import { format } from 'date-fns';
import { ISODateString } from '../types/common';
import i18next from '../i18n/i18n';

export const SECOND = 1000;
export const MINUTE = SECOND * 60;
export const HOUR = MINUTE * 60;
export const DAY = HOUR * 24;

export function formatDate(date: ISODateString, formatString?: string): string {
  let formattedDate = '';
  try {
    formattedDate = format(new Date(date), formatString || 'dd-MM-yyyy HH:mm');
  } catch {
    formattedDate = date;
  }
  return formattedDate;
}

export function formatDateFrom(date: ISODateString): string {
  const currentDateTimestamp = Date.now();
  const beginningDateTimestamp = new Date(date).valueOf();
  const difference = currentDateTimestamp - beginningDateTimestamp;
  if (difference < MINUTE) {
    return i18next.t('common.plurals.less_than_a_minute_ago');
  }
  if (difference >= MINUTE && difference < HOUR) {
    const minutes = Math.floor(difference / MINUTE);
    return i18next.t('common.plurals.minute', {
      postProcess: 'interval',
      count: minutes
    });
  }
  if (difference >= HOUR && difference < DAY) {
    const hours = Math.floor(difference / HOUR);
    return i18next.t('common.plurals.hour', {
      postProcess: 'interval',
      count: hours
    });
  }
  return formatDate(date);
}

export function dateSort(dateA: string | number | Date, dateB: string | number | Date) {
  const timestampA = new Date(dateA).getTime();
  const timestampB = new Date(dateB).getTime();

  if (timestampA > timestampB) {
    return 1;
  }

  if (timestampB > timestampA) {
    return -1;
  }

  return 0;
}
