export const SettingsPaths = {
  ORGANIZATION: '/organization',
  PACKAGE_LIST: '/packages',
  PACKAGE: '/packages/:id',
  PACKAGE_POINT_LIST: '/package-points',
  PACKAGE_POINT: '/package-points/:id',
  POLICIES_CONFIG: '/policies-config',
  FILEVAULT_ESCROW_CERTIFICATE: '/filevault-escrow-certificate',
  LICENSE: '/license',
  UPLOAD_CERTIFICATE: '/upload-certificate',
  SCRIPT_LIST: '/scripts',
  SCRIPT: '/scripts/:id',
  SCRIPTS_CONFIG: '/scripts-config',
  USER_LIST: '/users',
  USER: '/users/:id',
  ROLES_LIST: '/roles',
  ROLE: '/roles/:id',
  EXTENSION_ATTRIBUTES_LIST: '/extension-attributes',
  EXTENSION_ATTRIBUTE: '/extension-attributes/:id',
  LDAP: '/ldap',
  SCEP: '/scep',
  SMART_GROUPS_CONFIG: '/smart-groups-config',
  DEVICES_CONFIG: '/devices-config',
  PKI: '/pki',
  PKI_CERTIFICATE: '/pki/:id',
  PKI_TRUSTED_CA: '/pki/trusted-ca/:id',
  PKI_TRUSTED_CA_LIST: '/pki/trusted-ca'
};
