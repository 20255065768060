import React, { FC, useState } from 'react';
import ringoLogoImg from '../../../assets/images/logo.svg';
import ringoLogoWithTextImg from '../../../assets/images/logo-with-text.svg';

import { AsideHeader, FooterItem, LogoProps, MenuItem } from '@gravity-ui/navigation';
import {
  ArrowRightFromSquare,
  Book,
  CirclePlus,
  Cubes3,
  Display,
  Folder,
  FolderFlows,
  Gear,
  ListUl,
  Person,
  Persons,
  Smartphone
} from '@gravity-ui/icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import css from './sidebar.module.scss';
import cn from 'classnames';
import { Dialog } from '@gravity-ui/uikit';
import { DeviceSections, Paths } from '../../constants';
import { config } from '../../../config';
import { useAuthContext } from '../../contexts/auth.context';
import { usePermission } from '../../contexts/permission.context';
import { WithChildren } from '../../../types/common';
import { useSidebar } from '../../contexts/sidebar.context';
import {
  CommonSubPanelType,
  ComputersSubPanelType,
  MobilesSubPanelType,
  PanelType,
  UsersSubPanelType,
  useSubPanelType
} from './sidebar.const';
import { Permission } from '../../../types/api';

export const Sidebar: FC<WithChildren> = (props) => {
  const { children } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { authService } = useAuthContext();
  const { compact } = useSidebar();
  const { isAllowedTo } = usePermission();
  const { panelType, subPanelType } = useSubPanelType();

  const [visiblePanel, setVisiblePanel] = useState<PanelType | undefined>(panelType);
  const [visibleSubPanel, setVisibleSubPanel] = useState<
    ComputersSubPanelType | MobilesSubPanelType | UsersSubPanelType | CommonSubPanelType | undefined
  >(subPanelType);
  const [isLogOutModalOpen, setIsLogOutModalOpen] = useState(false);

  const logoPanel: LogoProps = {
    text: () => '',
    className: css.MainSideMenuLogo
  };

  const logoSubPanel: LogoProps = {
    text: () => <div style={{ width: '180px', height: '40px' }}></div>,
    iconSrc: compact ? ringoLogoImg : ringoLogoWithTextImg,
    iconSize: compact ? 25 : 136,
    onClick: () => navigate('/')
  };

  const panelMenuItems: MenuItem[] = [
    {
      id: PanelType.COMPUTERS,
      title: t('sidebar.computers'),
      icon: Display,
      pinned: true,
      current: visiblePanel === PanelType.COMPUTERS,
      onItemClick: () => setVisiblePanel(PanelType.COMPUTERS)
    },
    {
      id: PanelType.MOBILE,
      title: t('sidebar.mobiles'),
      icon: Smartphone,
      pinned: true,
      current: visiblePanel === PanelType.MOBILE,
      onItemClick: () => setVisiblePanel(PanelType.MOBILE)
    },
    {
      id: PanelType.USERS,
      title: t('sidebar.users'),
      icon: Person,
      pinned: true,
      current: visiblePanel === PanelType.USERS,
      onItemClick: () => setVisiblePanel(PanelType.USERS)
    }
  ];

  const computersMenuItems: MenuItem[] = [
    {
      id: ComputersSubPanelType.INVENTORY,
      title: t('sidebar.inventory'),
      icon: ListUl,
      current:
        visiblePanel === PanelType.COMPUTERS && visibleSubPanel === ComputersSubPanelType.INVENTORY,
      onItemClick: () => {
        setVisibleSubPanel(ComputersSubPanelType.INVENTORY);
        navigate(`${DeviceSections.COMPUTERS}/${Paths.INVENTORY}`);
      },
      hidden: !isAllowedTo(Permission.ReadDevices, Permission.EditDevices)
    },
    {
      id: ComputersSubPanelType.POLICIES,
      title: t('sidebar.policies'),
      icon: Book,
      current:
        visiblePanel === PanelType.COMPUTERS && visibleSubPanel === ComputersSubPanelType.POLICIES,
      onItemClick: () => {
        setVisibleSubPanel(ComputersSubPanelType.POLICIES);
        navigate(`${DeviceSections.COMPUTERS}/${Paths.POLICY_LIST}`);
      },
      hidden: !isAllowedTo(Permission.ReadPolicies, Permission.EditPolicies)
    },
    {
      id: ComputersSubPanelType.PROFILES,
      title: t('sidebar.profiles'),
      icon: Persons,
      current:
        visiblePanel === PanelType.COMPUTERS && visibleSubPanel === ComputersSubPanelType.PROFILES,
      onItemClick: () => {
        setVisibleSubPanel(ComputersSubPanelType.PROFILES);
        navigate(`${DeviceSections.COMPUTERS}/${Paths.CONFIGURATION_PROFILE_LIST}`);
      },
      hidden: !isAllowedTo(Permission.ReadProfiles, Permission.EditProfiles)
    },
    {
      id: ComputersSubPanelType.SMART_GROUPS,
      title: t('sidebar.smart_groups'),
      icon: FolderFlows,
      current:
        visiblePanel === PanelType.COMPUTERS &&
        visibleSubPanel === ComputersSubPanelType.SMART_GROUPS,
      onItemClick: () => {
        setVisibleSubPanel(ComputersSubPanelType.SMART_GROUPS);
        navigate(`${DeviceSections.COMPUTERS}/${Paths.SMART_GROUP_DEVICES_LIST}`);
      },
      hidden: !isAllowedTo(Permission.ReadGroups, Permission.EditGroups)
    },
    {
      id: ComputersSubPanelType.STATIC_GROUPS,
      title: t('sidebar.static_groups'),
      icon: Folder,
      current:
        visiblePanel === PanelType.COMPUTERS &&
        visibleSubPanel === ComputersSubPanelType.STATIC_GROUPS,
      onItemClick: () => {
        setVisibleSubPanel(ComputersSubPanelType.STATIC_GROUPS);
        navigate(`${DeviceSections.COMPUTERS}/${Paths.STATIC_GROUP_DEVICES_LIST}`);
      },
      hidden: !isAllowedTo(Permission.ReadGroups, Permission.EditGroups)
    },
    {
      id: ComputersSubPanelType.ENROLL,
      title: t('sidebar.enroll'),
      icon: CirclePlus,
      pinned: true,
      link: `${config.BACKEND_URL}/enroll`
    }
  ].filter((item) => !item.hidden);

  const mobilesMenuItems: MenuItem[] = [
    {
      id: MobilesSubPanelType.INVENTORY,
      title: t('sidebar.inventory'),
      icon: ListUl,
      pinned: true,
      current:
        visiblePanel === PanelType.MOBILE && visibleSubPanel === MobilesSubPanelType.INVENTORY,
      onItemClick: () => {
        setVisibleSubPanel(MobilesSubPanelType.INVENTORY);
        navigate(`${DeviceSections.MOBILE}/${Paths.INVENTORY}`);
      },
      hidden: !isAllowedTo(Permission.ReadDevices, Permission.EditDevices)
    },
    {
      id: MobilesSubPanelType.PROFILES,
      title: t('sidebar.profiles'),
      icon: Persons,
      pinned: true,
      current:
        visiblePanel === PanelType.MOBILE && visibleSubPanel === MobilesSubPanelType.PROFILES,
      onItemClick: () => {
        setVisibleSubPanel(MobilesSubPanelType.PROFILES);
        navigate(`${DeviceSections.MOBILE}/${Paths.CONFIGURATION_PROFILE_LIST}`);
      },
      hidden: !isAllowedTo(Permission.ReadProfiles, Permission.EditProfiles)
    },
    {
      id: MobilesSubPanelType.DEVICE_APPLICATIONS,
      title: t('sidebar.device_applications'),
      icon: Cubes3,
      pinned: true,
      current:
        visiblePanel === PanelType.MOBILE &&
        visibleSubPanel === MobilesSubPanelType.DEVICE_APPLICATIONS,
      onItemClick: () => {
        setVisibleSubPanel(MobilesSubPanelType.DEVICE_APPLICATIONS);
        navigate(`${DeviceSections.MOBILE}/${Paths.DEVICE_APPLICATION_LIST}`);
      },
      hidden: !isAllowedTo(Permission.ReadDeviceApplications, Permission.EditDeviceApplications)
    },
    {
      id: MobilesSubPanelType.SMART_GROUPS,
      title: t('sidebar.smart_groups'),
      icon: FolderFlows,
      current:
        visiblePanel === PanelType.MOBILE && visibleSubPanel === MobilesSubPanelType.SMART_GROUPS,
      onItemClick: () => {
        setVisibleSubPanel(MobilesSubPanelType.SMART_GROUPS);
        navigate(`${DeviceSections.MOBILE}/${Paths.SMART_GROUP_DEVICES_LIST}`);
      },
      hidden: !isAllowedTo(Permission.ReadGroups, Permission.EditGroups)
    },
    {
      id: MobilesSubPanelType.STATIC_GROUPS,
      title: t('sidebar.static_groups'),
      icon: Folder,
      current:
        visiblePanel === PanelType.MOBILE && visibleSubPanel === MobilesSubPanelType.STATIC_GROUPS,
      onItemClick: () => {
        setVisibleSubPanel(MobilesSubPanelType.STATIC_GROUPS);
        navigate(`${DeviceSections.MOBILE}/${Paths.STATIC_GROUP_DEVICES_LIST}`);
      },
      hidden: !isAllowedTo(Permission.ReadGroups, Permission.EditGroups)
    },
    {
      id: MobilesSubPanelType.ENROLL,
      title: t('sidebar.enroll'),
      icon: CirclePlus,
      pinned: true,
      link: `${config.BACKEND_URL}/enroll`
    }
  ].filter((item) => !item.hidden);

  const usersMenuItems: MenuItem[] = [
    {
      id: UsersSubPanelType.LIST,
      title: t('sidebar.users'),
      icon: ListUl,
      pinned: true,
      current: visiblePanel === PanelType.USERS && visibleSubPanel === UsersSubPanelType.LIST,
      onItemClick: () => {
        setVisibleSubPanel(UsersSubPanelType.LIST);
        navigate(`/${Paths.DEVICE_USER_LIST}`);
      },
      hidden: !isAllowedTo(Permission.ReadDevices, Permission.EditDevices)
    },
    {
      id: UsersSubPanelType.SMART_GROUPS,
      title: t('sidebar.smart_groups'),
      icon: FolderFlows,
      pinned: true,
      current:
        visiblePanel === PanelType.USERS && visibleSubPanel === UsersSubPanelType.SMART_GROUPS,
      onItemClick: () => {
        setVisibleSubPanel(UsersSubPanelType.SMART_GROUPS);
        navigate(`/${Paths.SMART_GROUP_DEVICE_USERS_LIST}`);
      },
      hidden: !isAllowedTo(Permission.ReadGroups, Permission.EditGroups)
    },
    {
      id: UsersSubPanelType.STATIC_GROUPS,
      title: t('sidebar.static_groups'),
      icon: Folder,
      pinned: true,
      current:
        visiblePanel === PanelType.USERS && visibleSubPanel === UsersSubPanelType.STATIC_GROUPS,
      onItemClick: () => {
        setVisibleSubPanel(UsersSubPanelType.STATIC_GROUPS);
        navigate(`/${Paths.STATIC_GROUP_DEVICE_USERS_LIST}`);
      },
      hidden: !isAllowedTo(Permission.ReadGroups, Permission.EditGroups)
    }
  ].filter((item) => !item.hidden);

  const subPanelItems: MenuItem[] =
    visiblePanel === PanelType.USERS
      ? usersMenuItems
      : visiblePanel === PanelType.MOBILE
      ? mobilesMenuItems
      : computersMenuItems;

  const footerItems: MenuItem[] = [
    {
      id: CommonSubPanelType.SETTINGS,
      title: t('sidebar.settings'),
      icon: Gear,
      pinned: true,
      current: visibleSubPanel === CommonSubPanelType.SETTINGS,
      onItemClick: () => {
        setVisibleSubPanel(CommonSubPanelType.SETTINGS);
        navigate(`/${Paths.SETTINGS}`);
      }
    },
    {
      id: CommonSubPanelType.EXIT,
      title: t('sidebar.log_out'),
      icon: ArrowRightFromSquare,
      onItemClick: () => setIsLogOutModalOpen(true)
    }
  ];

  return (
    <>
      <AsideHeader
        className={css.MainSideMenu}
        compact
        logo={logoPanel}
        menuItems={panelMenuItems}
        hideCollapseButton
        multipleTooltip
        renderContent={() => (
          <AsideHeader
            compact={compact}
            logo={logoSubPanel}
            menuItems={subPanelItems}
            hideCollapseButton
            multipleTooltip
            renderFooter={({ compact: compact_ }) => (
              <React.Fragment>
                {footerItems.map((item) => (
                  <FooterItem key={item.id} compact={compact_} item={item} />
                ))}
              </React.Fragment>
            )}
            renderContent={() => <div className={cn(css.ContentRoot)}>{children}</div>}
          />
        )}
      />
      <Dialog onClose={() => setIsLogOutModalOpen(false)} open={isLogOutModalOpen}>
        <Dialog.Header caption={t('log_out.title')} />
        <Dialog.Body>{t('log_out.confirmation_message')}</Dialog.Body>
        <Dialog.Footer
          onClickButtonCancel={() => setIsLogOutModalOpen(false)}
          onClickButtonApply={() => {
            authService.signOut();
            navigate(Paths.SIGN_IN);
          }}
          textButtonApply={t('log_out.log_out_btn')}
          textButtonCancel={t('log_out.cancel_btn')}
        />
      </Dialog>
    </>
  );
};
