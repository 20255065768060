/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AuthLoginRequestDto {
  username: string;
  password: string;
}

export enum Permission {
  ReadDevices = 'ReadDevices',
  EditDevices = 'EditDevices',
  ReadGroups = 'ReadGroups',
  EditGroups = 'EditGroups',
  ReadProfiles = 'ReadProfiles',
  EditProfiles = 'EditProfiles',
  ReadPolicies = 'ReadPolicies',
  EditPolicies = 'EditPolicies',
  ReadAdministration = 'ReadAdministration',
  EditAdministration = 'EditAdministration',
  ReadCommands = 'ReadCommands',
  EditCommands = 'EditCommands',
  ReadScripts = 'ReadScripts',
  EditScripts = 'EditScripts',
  ReadPackages = 'ReadPackages',
  EditPackages = 'EditPackages',
  ReadDeviceUsers = 'ReadDeviceUsers',
  EditDeviceUsers = 'EditDeviceUsers',
  ReadDeviceApplications = 'ReadDeviceApplications',
  EditDeviceApplications = 'EditDeviceApplications'
}

export interface RoleSimpleDto {
  /**
   * id
   * @format uuid
   */
  id: string;
  /**
   * Создано
   * @format date-time
   */
  created_at: string;
  /**
   * Обновлено
   * @format date-time
   */
  updated_at: string;
  /** Наименование */
  title: string;
  /**
   * Список прав
   * @example ["ReadDevices","EditDevices","ReadGroups","EditGroups","ReadProfiles","EditProfiles","ReadPolicies","EditPolicies","ReadAdministration","EditAdministration","ReadCommands","EditCommands","ReadScripts","EditScripts","ReadPackages","EditPackages","ReadDeviceUsers","EditDeviceUsers","ReadDeviceApplications","EditDeviceApplications"]
   */
  permissions?: Permission[];
  /** Владалец окружения */
  superadmin: boolean;
}

export interface AuthLoginResponseDto {
  id: string;
  role: RoleSimpleDto;
  username: string;
  access_token: string;
}

export interface AuthRefreshResponseDto {
  id: string;
  role: RoleSimpleDto;
  username: string;
  access_token: string;
}

export enum UserOriginType {
  Local = 'local',
  Ldap = 'ldap'
}

export interface UserSimpleDto {
  /** @format uuid */
  id: string;
  /**
   * Создано
   * @format date-time
   */
  created_at: string;
  /**
   * Обновлено
   * @format date-time
   */
  updated_at: string;
  username: string;
  display_name: string;
  role: RoleSimpleDto;
  origin_type: UserOriginType;
}

export interface CreateUserRequestDto {
  /**
   * Имя пользователя
   * @maxLength 256
   */
  username: string;
  /** Пароль */
  password?: string;
  /**
   * Отображаемое имя
   * @maxLength 256
   */
  display_name?: string;
  /**
   * UUID Роли пользователя
   * @format uuid
   */
  role: string;
  /** Тип источника */
  origin_type: UserOriginType;
}

export interface GetUserListResponseDto {
  count: number;
  users: UserSimpleDto[];
}

export interface UpdateUserRequestDto {
  /**
   * Имя пользователя
   * @maxLength 256
   */
  username?: string;
  /** Пароль */
  password?: string;
  /**
   * Отображаемое имя
   * @maxLength 256
   */
  display_name?: string;
  /**
   * UUID Роли пользователя
   * @format uuid
   */
  role?: string;
}

export interface LdapInstanceMappingsResponseDto {
  username: string;
  full_name: string;
  email: string;
}

export interface LdapInstanceFullDto {
  /** @format uuid */
  id: string;
  /**
   * Создано
   * @format date-time
   */
  created_at: string;
  /**
   * Обновлено
   * @format date-time
   */
  updated_at: string;
  display_name: string;
  hostname: string;
  ssl: boolean;
  port: string | null;
  base_dn: string | null;
  filter: string | null;
  user_dn: string | null;
  password: string | null;
  sync_enabled: boolean;
  sync_interval: number;
  timeout_connect: number;
  timeout_search: number;
  has_ca_certificate: boolean;
  mappings: LdapInstanceMappingsResponseDto;
}

export interface LdapInstanceMappingsRequestDto {
  username?: string;
  full_name?: string;
  email?: string;
}

export interface SaveLdapInstanceDto {
  display_name: string;
  hostname: string;
  ssl?: boolean;
  port?: string | null;
  base_dn?: string | null;
  filter?: string | null;
  user_dn?: string | null;
  password?: string | null;
  sync_enabled?: boolean;
  /**
   * @min 60
   * @max 43200
   */
  sync_interval?: number;
  /**
   * @min 5
   * @max 3600
   */
  timeout_connect?: number;
  /**
   * @min 5
   * @max 3600
   */
  timeout_search?: number;
  ca_certificate?: string;
  /** LdapInstanceMappingsRequestDto */
  mappings?: LdapInstanceMappingsRequestDto;
}

export interface TestLdapConnectionDto {
  hostname: string;
  ssl: boolean;
  port?: string;
  base_dn?: string;
  user_dn?: string;
  password?: string;
  ca_certificate?: string;
  /**
   * @min 5
   * @max 3600
   */
  timeout_connect?: number;
  /**
   * @min 5
   * @max 3600
   */
  timeout_search?: number;
}

export interface SearchLdapUsersRequestDto {
  search?: string;
}

export interface SearchLdapUsersEntryDto {
  dn: string;
  username: string[];
  full_name: string[];
  email: string[];
}

export interface SearchLdapUsersResponseDto {
  entries: SearchLdapUsersEntryDto[];
}

export enum PkiCertificatesSortField {
  IssueDate = 'issue_date',
  ExpirationDate = 'expiration_date',
  Type = 'type'
}

export enum PaginationSortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export interface GetPkiCertificatesRequestDto {
  /** @min 1 */
  page?: number;
  /** @min 1 */
  limit?: number;
  sort_field?: PkiCertificatesSortField;
  sort_order?: PaginationSortOrder;
}

export enum PkiCertificateType {
  CaRootBuiltIn = 'ca-root-built-in',
  FilevaultBuiltIn = 'filevault-built-in',
  ConfigProfileSign = 'config-profile-sign',
  EnrollProfileSign = 'enroll-profile-sign',
  DeviceIdentity = 'device-identity',
  AgentDeviceIdentity = 'agent-device-identity',
  CaTrusted = 'ca-trusted'
}

export interface PkiCertificateSimpleDto {
  /** @format uuid */
  id: string;
  /**
   * Создано
   * @format date-time
   */
  created_at: string;
  /**
   * Обновлено
   * @format date-time
   */
  updated_at: string;
  type: PkiCertificateType;
  serial_number: string;
  issuer: string;
  subject: string;
  /** @format date-time */
  issue_date: string;
  /** @format date-time */
  expiration_date: string;
}

export interface GetPkiCertificatesResponseDto {
  count: number;
  certificates: PkiCertificateSimpleDto[];
}

export enum DeviceOsType {
  IOS = 'iOS',
  MacOS = 'macOS'
}

export interface DeviceMinimalDto {
  id: string;
  udid: string;
  serial_number: string;
  device_name: string | null;
  os_type: DeviceOsType;
}

export interface PkiCertificateFullDto {
  /** @format uuid */
  id: string;
  /**
   * Создано
   * @format date-time
   */
  created_at: string;
  /**
   * Обновлено
   * @format date-time
   */
  updated_at: string;
  type: PkiCertificateType;
  serial_number: string;
  issuer: string;
  subject: string;
  /** @format date-time */
  issue_date: string;
  /** @format date-time */
  expiration_date: string;
  devices: DeviceMinimalDto[];
}

export interface ImportTrustedCaCertificateRequestDto {
  /**
   * Файл сертификата (.pem, .cer)
   * @format binary
   */
  certificate: File;
}

export interface DownloadCertificateBackupDto {
  /** @maxLength 100 */
  password: string;
}

export interface CreateCertificateFromCsrDto {
  csr: string;
}

export interface CreateRoleDto {
  /**
   * Название роли
   * @maxLength 256
   */
  title: string;
  /**
   * Список прав
   * @example ["ReadDevices","EditDevices","ReadGroups","EditGroups","ReadProfiles","EditProfiles","ReadPolicies","EditPolicies","ReadAdministration","EditAdministration","ReadCommands","EditCommands","ReadScripts","EditScripts","ReadPackages","EditPackages","ReadDeviceUsers","EditDeviceUsers","ReadDeviceApplications","EditDeviceApplications"]
   */
  permissions?: Permission[];
  /** Владелец окружения */
  superadmin?: boolean;
}

export interface GetRolesResponseDto {
  /** Количество */
  count: number;
  /** Список ролей */
  roles: RoleSimpleDto[];
}

export interface UpdateRoleDto {
  /**
   * Название роли
   * @maxLength 256
   */
  title?: string;
  /**
   * Список прав
   * @example ["ReadDevices","EditDevices","ReadGroups","EditGroups","ReadProfiles","EditProfiles","ReadPolicies","EditPolicies","ReadAdministration","EditAdministration","ReadCommands","EditCommands","ReadScripts","EditScripts","ReadPackages","EditPackages","ReadDeviceUsers","EditDeviceUsers","ReadDeviceApplications","EditDeviceApplications"]
   */
  permissions?: Permission[];
  /** Владелец окружения */
  superadmin?: boolean;
}

export enum LicenseStatus {
  Expired = 'expired',
  Active = 'active'
}

export interface LicenseDto {
  license_code: string;
  /** @format date-time */
  expires_at: string;
  /** @format date-time */
  activated_at: string | null;
  max_device: number;
  status: LicenseStatus;
  os_type: DeviceOsType;
}

export interface GetActiveLicensesDto {
  macos_license: LicenseDto;
  ios_license: LicenseDto;
}

export interface GetLicenseListDto {
  licenses: LicenseDto[];
  count: number;
}

export interface UploadLicenseFileRequestDto {
  /** @format binary */
  license: File;
  os_type: DeviceOsType;
}

export interface DeviceActivationInfoDto {
  /** Код сброса activation lock */
  activation_lock_bypass_code?: string | null;
  /** Ошибка */
  error?: string | null;
}

export enum CommandStatus {
  Pending = 'pending',
  Failed = 'failed'
}

export interface GetCommandsRequestDto {
  /** Статус */
  status: CommandStatus;
}

export enum DevicesSortField {
  DisplayName = 'display_name',
  ModelName = 'model_name',
  ModelAndSerial = 'model_and_serial',
  OsVersion = 'os_version',
  SerialNumber = 'serial_number',
  LastSeen = 'last_seen'
}

export interface GetDevicesRequestDto {
  /**
   * Номер страницы списка устройств.
   * @min 1
   */
  page?: number;
  /**
   * Количество выыводимых на одной странице устройств.
   * @min 1
   */
  limit?: number;
  /** Поле сортировки устройств */
  sort_field?: DevicesSortField;
  /** Порядок сортировки устройств */
  sort_order?: PaginationSortOrder;
  /** Выводить все устройства */
  get_all?: boolean;
  /** Тип ОС устройств */
  os_type: DeviceOsType;
}

export interface SearchDeviceByConditionDto {
  /** Критерий */
  criteria: string;
  /** Оператор */
  operator: '=' | '!=' | 'like' | 'not like' | '>' | '>=' | '<' | '<=';
  /** Значение */
  value: string;
  /** Логическая операция */
  conjunctive?: 'and' | 'or' | null;
  /** Открывающаяся скобка */
  start_bracket?: boolean;
  /** Закрывающаяся скобка */
  end_bracket?: boolean;
}

export interface GetDevicesCSVRequestDto {
  /** Фильтр применен */
  is_filtered: boolean;
  /** Тип ОС */
  os_type: DeviceOsType;
  /** Условия */
  conditions?: SearchDeviceByConditionDto[] | null;
}

export interface GetCriterionValuesRequestDto {
  /** Тип ОС */
  os_type: DeviceOsType;
  /** Критерий */
  criterion: string;
}

export interface GetCriteriaRequestDto {
  /** Для поиска */
  forSearch: boolean;
}

export interface SetFirmwarePasswordResponseDto {
  /** Ошибка */
  error?: string | null;
}

export interface SetRecoveryLockResponseDto {
  /** Ошибка */
  error?: string | null;
}

export interface ClearPasscodeResponseDto {
  /** Ошибка */
  error?: string | null;
}

export interface EnableLostModeRequestDto {
  /** Подпись */
  footnote?: string;
  /** Сообщение */
  message?: string;
  /** Номер телефона */
  phone_number?: string;
}

export interface EnableLostModeResponseDto {
  /** Ошибка */
  error?: string | null;
}

export enum DeviceCriteriaType {
  String = 'string',
  Number = 'number',
  Boolean = 'boolean',
  Date = 'date'
}

export interface DeviceCriteriaDto {
  /** Наименование */
  name: string;
  /** Тип данных критерия */
  type: DeviceCriteriaType;
}

export interface DeviceCriteriaResponseDto {
  /** Количество */
  count: number;
  /** Критерий */
  criteria: DeviceCriteriaDto[];
}

export interface DeviceLockMobileRequestDto {
  /** Сообщение */
  message?: string;
  /** Номер телефона */
  phone_number?: string;
}

export interface DeviceInformationSimpleDto {
  /**
   * Создано в
   * @format date-time
   */
  created_at: string;
  /**
   * Обновлено в
   * @format date-time
   */
  updated_at: string;
  /** Версия ОС */
  os_version: string | null;
  /** Имя устройства */
  device_name: string | null;
}

export interface DeviceSimpleDto {
  /** ID */
  id: string;
  /** UDID */
  udid: string;
  /**
   * Создано
   * @format date-time
   */
  created_at: string;
  /**
   * Обновлено
   * @format date-time
   */
  updated_at: string;
  /** Серийный номер */
  serial_number: string;
  /** Номер модели */
  model_name: string | null;
  /** Статус энролла агента */
  agent_enrollment_status: boolean;
  /** Добавлено в MDM */
  mdm_enrollment_status: boolean;
  /** Тип ОС */
  os_type: DeviceOsType;
  /**
   * Был в
   * @format date-time
   */
  last_seen: string;
  /** Статус DEP профиля */
  dep_profile_status: string | null;
  /** Информация об устройстве */
  device_information?: DeviceInformationSimpleDto;
}

export interface GetDevicesResponseDto {
  /** Количество устройств */
  count: number;
  /** Список устройств */
  devices: DeviceSimpleDto[];
}

export interface GetDevicesCountResponseDto {
  /** Количество устройств */
  count: number;
}

export interface SearchDevicesRequestDto {
  /** Условия */
  conditions: SearchDeviceByConditionDto[];
  /** Тип ОС */
  os_type: DeviceOsType;
  /**
   * Страница
   * @min 1
   */
  page?: number;
  /**
   * Выводить на странице
   * @min 1
   */
  limit?: number;
  /** Поле сортировки */
  sort_field?: DevicesSortField;
  /** Порядок сортировки */
  sort_order?: PaginationSortOrder;
  /** Загрузить все устройства */
  get_all?: boolean;
}

export enum MicroMdmCommandRequestType {
  DeviceInformation = 'DeviceInformation',
  InstalledApplicationList = 'InstalledApplicationList',
  SecurityInfo = 'SecurityInfo',
  RestartDevice = 'RestartDevice',
  InstallApplication = 'InstallApplication',
  RemoveApplication = 'RemoveApplication',
  InstallProfile = 'InstallProfile',
  RemoveProfile = 'RemoveProfile',
  DeviceLock = 'DeviceLock',
  ScheduleOSUpdate = 'ScheduleOSUpdate',
  OSUpdateStatus = 'OSUpdateStatus',
  EraseDevice = 'EraseDevice',
  Settings = 'Settings',
  ActivationLockBypassCode = 'ActivationLockBypassCode',
  EnableRemoteDesktop = 'EnableRemoteDesktop',
  DisableRemoteDesktop = 'DisableRemoteDesktop',
  SetFirmwarePassword = 'SetFirmwarePassword',
  SetRecoveryLock = 'SetRecoveryLock',
  ClearPasscode = 'ClearPasscode',
  ProfileList = 'ProfileList',
  ProvisioningProfileList = 'ProvisioningProfileList',
  CertificateList = 'CertificateList',
  EnableLostMode = 'EnableLostMode',
  DisableLostMode = 'DisableLostMode'
}

export interface CommandDto {
  /** @format uuid */
  id: string;
  /**
   * Создано
   * @format date-time
   */
  created_at: string;
  /**
   * Обновлено
   * @format date-time
   */
  updated_at: string;
  /** Тип команды */
  command_type: MicroMdmCommandRequestType;
}

export interface GetCommandsResponseDto {
  /** Количество */
  count: number;
  /** Команды */
  commands: CommandDto[];
}

export interface DeviceInformationFullDto {
  /**
   * Создано в
   * @format date-time
   */
  created_at: string;
  /**
   * Обновлено в
   * @format date-time
   */
  updated_at: string;
  /** Версия ОС */
  os_version: string | null;
  /** Имя устройства */
  device_name: string | null;
  /** Объем накопителя */
  device_capacity: number | null;
  /** Доступный объем накопителя */
  available_device_capacity: number | null;
  /** Занятый объем накопителя */
  used_space: number | null;
  /** Управляемое */
  is_supervised: boolean | null;
  /** Id обновлений устройства  */
  software_update_device_id: string | null;
  /** Локальный IP */
  local_ip: string | null;
  /** Тип процессора */
  cpu_type: string | null;
  /** Процессор типа Apple silicon */
  is_apple_silicon: boolean | null;
  /** Главный интерфейс связи */
  main_network_interface: string | null;
  /** MAC-адрес главного интерфейса связи */
  main_network_interface_mac: string | null;
  /** Идентификатор модели */
  model_identifier: string | null;
  /** Полный объем ОЗУ */
  total_ram_mb: number | null;
  /** Объем батареи */
  battery_capacity: string | null;
  /** Имя ОС */
  os_name: string | null;
  /** Версия сборки ОС */
  os_build_version: string | null;
  /** Ответ безопасности ОС */
  os_rapid_security_response: string | null;
  /** Состояние */
  os_ad_bind: boolean | null;
  /** Пароль прошивки включен */
  firmware_password_enabled: boolean | null;
  /** Пароль прошивки */
  firmware_password: string | null;
  /** ARD включен */
  ard_enabled: boolean | null;
  /** SIP включена */
  sip_enabled: boolean | null;
  /** Activation Lock включен */
  activation_lock_enabled: boolean | null;
  /** Activation Lock */
  activation_lock: string | null;
  /** Версия Xprotect Definitions */
  x_protect_definitions_version: string | null;
  /** Брандмауэр Включен */
  firewall_enabled: boolean | null;
  /** Автологин Включен */
  autologin_enabled: boolean | null;
  /** Gatekeeper Включен */
  gatekeeper_enabled: boolean | null;
  /** Пароль восстановления включен */
  recovery_lock_enabled: boolean | null;
  /** Пароль восстановления */
  recovery_lock: string | null;
  /** Серийный номер главного диска */
  primary_drive_serial: string | null;
  /** Модель главного диска */
  primary_drive_model: string | null;
  /** Статус S.M.A.R.T. главного диска */
  primary_drive_smart_status: string | null;
  /** Ревизия главного диска */
  primary_drive_revision: string | null;
  /** Общая емкость главного диска */
  primary_drive_capacity_mb: number | null;
  /** Шифрование диска включено */
  filevault_enabled: boolean | null;
  /** Статус институционального ключа восстановления */
  filevault_has_institutional_key: boolean | null;
  /** FileVault имеет собственный ключ */
  filevault_has_personal_key: boolean | null;
  /** Персональный ключ восстановления */
  personal_recovery_key: string | null;
  /** Пин-код разблокировки */
  lock_pin: string | null;
  /** Часовой пояс */
  time_zone: string | null;
  /** Связь привязана */
  is_network_tethered: boolean | null;
  /** EAS идентификатор устройства */
  eas_device_identifier: string | null;
  /** Режим "Не беспокоить включен" */
  is_do_not_disturb_in_effect: boolean | null;
  supplemental_os_version_extra: string | null;
  /** Версия сборки */
  build_version: string | null;
  supplemental_build_version: string | null;
  /** iCloud backup включен */
  is_cloud_backup_enabled: boolean | null;
  /**
   * Дата последнего iCloud backup
   * @format date-time
   */
  last_cloud_backup_date: string | null;
  /** Уровень заряда */
  battery_level: string | null;
  /** MAC-адрес Wi-Fi контроллера */
  wifi_mac: string | null;
  /** MAC-адрес Bluetooth контроллера */
  bluetooth_mac: string | null;
  /** Версия прошивки модема */
  modem_firmware_version: string | null;
  /** Локатор включен */
  is_device_locator_service_enabled: boolean | null;
  /** MDM режим потери включен */
  is_mdm_lost_mode_enabled: boolean | null;
  /** Модель */
  model: string | null;
  /** номер модели */
  model_number: string | null;
  /** Значение переменной шифрования */
  hardware_encryption_caps: number | null;
  /** Соответствие политики паролей */
  passcode_compliant: boolean | null;
  /** Соответствие политики паролей профилю */
  passcode_compliant_with_profiles: boolean | null;
  /** Принудительный период блокировки пароля включен */
  passcode_lock_grace_period_enforced: number | null;
}

export interface AgentInstanceSimpleDto {
  /** @format uuid */
  id: string;
  /**
   * Создано
   * @format date-time
   */
  created_at: string;
  /**
   * Обновлено
   * @format date-time
   */
  updated_at: string;
  /**
   * Последний удаленный адрес
   * @default null
   */
  last_remote_address: string | null;
}

export interface DeviceApplicationSimpleDto {
  /** @format uuid */
  id: string;
  /**
   * Создано
   * @format date-time
   */
  created_at: string;
  /**
   * Обновлено
   * @format date-time
   */
  updated_at: string;
  /** Наименование */
  name: string | null;
  /** Версия */
  version: string | null;
  /** Путь */
  path: string | null;
}

export interface DeviceProfileSimpleDto {
  /** @format uuid */
  id: string;
  /**
   * Создано
   * @format date-time
   */
  created_at: string;
  /**
   * Обновлено
   * @format date-time
   */
  updated_at: string;
  /** Идентификатор */
  identifier: string | null;
  /** Наименование */
  name: string | null;
  /** UUID */
  uuid: string | null;
}

export interface DeviceProvisioningProfileSimpleDto {
  /** @format uuid */
  id: string;
  /**
   * Создано
   * @format date-time
   */
  created_at: string;
  /**
   * Обновлено
   * @format date-time
   */
  updated_at: string;
  /** Дата истечения */
  expiry_date: string | null;
  /** Наименование */
  name: string | null;
  /** UUID */
  uuid: string | null;
}

export interface DeviceCertificateSimpleDto {
  /** @format uuid */
  id: string;
  /**
   * Создано
   * @format date-time
   */
  created_at: string;
  /**
   * Обновлено
   * @format date-time
   */
  updated_at: string;
  /** Наименование */
  common_name: string | null;
  /** Является identity сертификатом */
  is_identity: boolean | null;
}

export interface DeviceUserAccountSimpleDto {
  /** @format uuid */
  id: string;
  /**
   * Создано
   * @format date-time
   */
  created_at: string;
  /**
   * Обновлено
   * @format date-time
   */
  updated_at: string;
  /** GID */
  gid: string | null;
  /** UID */
  uid: string | null;
  /** Директория */
  dir: string | null;
  /** Имя */
  name: string | null;
  /** Администратор */
  is_admin: boolean | null;
}

export interface DeviceServiceSimpleDto {
  /** @format uuid */
  id: string;
  /**
   * Создано
   * @format date-time
   */
  created_at: string;
  /**
   * Обновлено
   * @format date-time
   */
  updated_at: string;
  /** Наименование */
  name: string | null;
}

export interface DeviceUserSimpleDto {
  /** @format uuid */
  id: string;
  /**
   * Создано
   * @format date-time
   */
  created_at: string;
  /**
   * Обновлено
   * @format date-time
   */
  updated_at: string;
  username: string;
  email: string | null;
  employee_name: string | null;
  phone_number: string | null;
  position: string | null;
  computers_count?: number;
  mobile_devices_count?: number;
}

export interface ExtensionAttribute {
  display_name: string;
  description: string | null;
  data_type: 'String' | 'Number' | 'Date';
  inventory_display:
    | 'General'
    | 'Hardware'
    | 'OS'
    | 'Security'
    | 'Storage'
    | 'Applications'
    | 'ExtensionAttributes';
  input_type: 'Text' | 'Select' | 'Script';
  options: string[] | null;
  script: string | null;
  slug: string;
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
}

export interface DeviceExtensionAttributeDto {
  /** @format uuid */
  id: string;
  /**
   * Создано
   * @format date-time
   */
  created_at: string;
  /**
   * Обновлено
   * @format date-time
   */
  updated_at: string;
  /** Дополнительный атрибут */
  extension_attribute: ExtensionAttribute | null;
  /** Значение дополнительного атрибута */
  value: string | null;
}

export interface DeviceFullDto {
  /** ID */
  id: string;
  /** UDID */
  udid: string;
  /**
   * Создано
   * @format date-time
   */
  created_at: string;
  /**
   * Обновлено
   * @format date-time
   */
  updated_at: string;
  /** Серийный номер */
  serial_number: string;
  /** Номер модели */
  model_name: string | null;
  /** Статус энролла агента */
  agent_enrollment_status: boolean;
  /** Добавлено в MDM */
  mdm_enrollment_status: boolean;
  /** Тип ОС */
  os_type: DeviceOsType;
  /**
   * Был в
   * @format date-time
   */
  last_seen: string;
  /** Статус DEP профиля */
  dep_profile_status: string | null;
  /** Информация об устройстве */
  device_information?: DeviceInformationFullDto;
  /** Экземпляр агента */
  agent_instance?: AgentInstanceSimpleDto;
  /** Приложения */
  applications?: DeviceApplicationSimpleDto[];
  /** Профили */
  profiles?: DeviceProfileSimpleDto[];
  /** Профили обеспечения */
  provisioning_profiles?: DeviceProvisioningProfileSimpleDto[];
  /** Сертификаты */
  certificates?: DeviceCertificateSimpleDto[];
  /** Аккаунты пользователей */
  user_accounts?: DeviceUserAccountSimpleDto[];
  /** Сервисы */
  services?: DeviceServiceSimpleDto[];
  /** Пользователь устройства */
  device_user?: DeviceUserSimpleDto;
  /** Дополнительные аттрибуты */
  device_extension_attributes?: DeviceExtensionAttributeDto[];
}

export interface LinkToUserDto {
  /** ID пользователя устройства */
  device_user_id: string;
}

export interface DeviceCommandResponseDto {
  /** UDID Устройства */
  udid: string;
  /** UUID Команды */
  command_uuid: string | null;
  /** В очереди */
  queued: boolean;
  /** Завершена */
  completed: boolean;
  /** Нагрузка */
  payload?: object | null;
}

export interface DeviceLockRequestDto {
  /** Сообщение */
  message: string;
  /**
   * PIN
   * @minLength 6
   * @maxLength 6
   */
  pin: string;
  /** Номер телефона */
  phone_number?: string;
}

export interface EraseDeviceRequestDto {
  /**
   * PIN-код
   * @minLength 6
   * @maxLength 6
   */
  pin?: string;
}

export enum InstallActionType {
  InstallASAP = 'InstallASAP',
  DownloadOnly = 'DownloadOnly'
}

export interface PerformOsUpdateActionDto {
  /** Действие */
  install_action: InstallActionType;
  /** Ключ продукта */
  product_key?: string;
}

export interface SetFirmwarePasswordRequestDto {
  /** Текущий пароль */
  current_password?: string;
  /** Новый пароль */
  new_password?: string;
}

export interface SetRecoveryLockRequestDto {
  /** Текущий пароль */
  current_password?: string;
  /** Новый пароль */
  new_password?: string;
}

export interface UpdateExtensionAttributeValueDto {
  /** ID дополнительного атрибута */
  extension_attribute_id: string;
  /** Значение дополнительного атрибута */
  value?: string | null;
}

export type Buffer = object;

export interface UploadPushCertificateRequestDto {
  /**
   * Файл сертификата (.pem)
   * @format binary
   */
  certificate: File;
  /**
   * Файл приватного ключа (.key)
   * @format binary
   */
  private_key: File;
  /**
   * Пароль
   * @example "secret"
   */
  password?: string;
}

export interface CheckSettingsRequestDto {
  names: (
    | 'organization_name'
    | 'policy_custom_script_timeout'
    | 'policy_leave_history_on_delete'
    | 'get_device_information_timeout'
    | 'use_server_as_scep_proxy'
    | 'scep_certificate_url'
    | 'smart_groups_update_interval'
    | 'mobile_devices_online_check_interval'
    | 'mobile_devices_inventory_update_settings'
  )[];
}

export enum MobileDevicesInventoryUpdateFrequency {
  ONCE_PER_DAY = 'oncePerDay',
  ONCE_PER_WEEK = 'oncePerWeek',
  ONCE_PER_TWO_WEEKS = 'oncePerTwoWeeks',
  ONCE_PER_MONTH = 'oncePerMonth',
}

export interface MobileDevicesInventoryUpdateSettingsDto {
  frequency: MobileDevicesInventoryUpdateFrequency;
  time: string;
  timezoneShiftMinutes: number;
  dayOfWeek: number;
  dayOfMonth: number;
}

export interface CheckSettingsResponseDto {
  /** Имя организации */
  organization_name: boolean;
  /** Ключ сертификата filevault escrow */
  filevault_escrow_cert_key: boolean;
  /** Пароль ключа сертификата filevault escrow */
  filevault_escrow_cert_key_secret: boolean;
  /** Таймаут скрипта в политиках */
  policy_custom_script_timeout: boolean;
  /** Таймаут получения информации об устройстве */
  get_device_information_timeout: boolean;
  /** Оставлять историю политик при удалении и перепривязке устройства */
  policy_leave_history_on_delete: boolean;
  use_server_as_scep_proxy: boolean;
  scep_certificate_url: boolean;
  smart_groups_update_interval: boolean;
  mobile_devices_online_check_interval: boolean;
  mobile_devices_inventory_update_settings: boolean;
}

export interface GetSettingsResponseDto {
  /** Имя организации */
  organization_name: string | null;
  /** Ключ сертификата filevault escrow */
  filevault_escrow_cert_key: string | null;
  /** Пароль ключа сертификата filevault escrow */
  filevault_escrow_cert_key_secret: string | null;
  /** Таймаут скрипта в политиках */
  policy_custom_script_timeout: number | null;
  /** Таймаут получения информации об устройстве */
  get_device_information_timeout: number | null;
  /** Использовать сервер как прокси для SCEP */
  use_server_as_scep_proxy: boolean | null;
  /** URL для получения устройствами сертификатов со SCEP сервера */
  scep_certificate_url: string | null;
  /** Интервал обновления смарт-групп */
  smart_groups_update_interval: number | null;
  /** Интервал проверки подключения мобильных устройств */
  mobile_devices_online_check_interval: number | null;
  /** Настройки рассписания обновления информации о мобильных устройствах */
  mobile_devices_inventory_update_settings: MobileDevicesInventoryUpdateSettingsDto | null;
  /** Оставлять историю политик при удалении и перепривязке устройства */
  policy_leave_history_on_delete: boolean | null;
}

export interface UpdateSettingsDto {
  /** Имя организации */
  organization_name: string | null;
  /** Ключ сертификата filevault escrow */
  filevault_escrow_cert_key: string | null;
  /** Пароль ключа сертификата filevault escrow */
  filevault_escrow_cert_key_secret: string | null;
  /**
   * Таймаут скрипта в политиках
   * @min 60
   * @max 86400
   */
  policy_custom_script_timeout: number | null;
  /**
   * Таймаут получения информации об устройстве
   * @min 60
   * @max 43200
   */
  get_device_information_timeout: number | null;
  /** Использовать сервер как прокси для SCEP */
  use_server_as_scep_proxy: boolean | null;
  /** URL для получения устройствами сертификатов со SCEP сервера */
  scep_certificate_url: string | null;
  /**
   * Интервал обновления смарт-групп
   * @min 1
   * @max 720
   */
  smart_groups_update_interval: number | null;
  /**
   * Интервал проверки подключения мобильных устройств
   * @min 1
   * @max 720
   */
  mobile_devices_online_check_interval: number | null;
  /** Настройки рассписания обновления информации о мобильных устройствах */
  mobile_devices_inventory_update_settings: MobileDevicesInventoryUpdateSettingsDto | null;
  /** Оставлять историю политик при удалении и перепривязке устройства */
  policy_leave_history_on_delete: boolean;
}

export interface CheckAgentStatusResponseDto {
  /** Онлайн */
  online: boolean;
}

export interface SendAgentCommandRequestDto {
  input: string;
}

export interface SendAgentCommandResponseDto {
  /** Вывод */
  output: string[];
  /** Успешно */
  success: boolean | null;
}

export type StreamableFile = object;

export enum ExtensionAttributeDataType {
  String = 'String',
  Number = 'Number',
  Date = 'Date'
}

export enum ExtensionAttributeInventorySection {
  General = 'General',
  Hardware = 'Hardware',
  OS = 'OS',
  Security = 'Security',
  Storage = 'Storage',
  Applications = 'Applications',
  ExtensionAttributes = 'ExtensionAttributes'
}

export enum ExtensionAttributeInputType {
  Text = 'Text',
  Select = 'Select',
  Script = 'Script'
}

export interface CreateExtensionAttributeDto {
  /**
   * Наименование
   * @maxLength 256
   */
  display_name: string;
  /** Описание */
  description?: string | null;
  /**
   * Тип данных
   * @default "String"
   */
  data_type: ExtensionAttributeDataType;
  /**
   * Секция для отображения в информации об устройстве
   * @default "ExtensionAttributes"
   */
  inventory_display: ExtensionAttributeInventorySection;
  /**
   * Способ ввода
   * @default "Text"
   */
  input_type: ExtensionAttributeInputType;
  /** Варианты */
  options?: string[];
  /** Скрипт */
  script?: string;
  /**
   * Строка названия переменной
   * @pattern /^[\dA-Z]+$/
   * @example "VALUE1"
   */
  slug?: string;
}

export interface ExtensionAttributeFullDto {
  /** @format uuid */
  id: string;
  /**
   * Создано
   * @format date-time
   */
  created_at: string;
  /**
   * Обновлено
   * @format date-time
   */
  updated_at: string;
  /** Наименование */
  display_name: string;
  /** Описание */
  description: string | null;
  /**
   * Тип данных
   * @default "String"
   */
  data_type: ExtensionAttributeDataType;
  /**
   * Секция для отображения в информации об устройстве
   * @default "ExtensionAttributes"
   */
  inventory_display: ExtensionAttributeInventorySection;
  /**
   * Способ ввода
   * @default "Text"
   */
  input_type: ExtensionAttributeInputType;
  /** Варианты */
  options: string[] | null;
  /** Скрипт */
  script: string | null;
  /**
   * Строка названия переменной
   * @example "VALUE1"
   */
  slug: string;
}

export interface GetExtensionAttributesResponseDto {
  /** Количество */
  count: number;
  /** Дополнительные атрибуты */
  extension_attributes: ExtensionAttributeFullDto[];
}

export interface UpdateExtensionAttributeDto {
  /**
   * Наименование
   * @maxLength 256
   */
  display_name?: string;
  /** Описание */
  description?: string | null;
  /**
   * Тип данных
   * @default "String"
   */
  data_type?: ExtensionAttributeDataType;
  /**
   * Секция для отображения в информации об устройстве
   * @default "ExtensionAttributes"
   */
  inventory_display?: ExtensionAttributeInventorySection;
  /**
   * Способ ввода
   * @default "Text"
   */
  input_type?: ExtensionAttributeInputType;
  /** Варианты */
  options?: string[];
  /** Скрипт */
  script?: string;
  /**
   * Строка названия переменной
   * @pattern /^[\dA-Z]+$/
   * @example "VALUE1"
   */
  slug?: string;
}

export enum HistoryElementType {
  Device = 'device'
}

export enum HistorySortField {
  HistoryType = 'history_type',
  AdditionalInfo = 'additional_info',
  CreatedAt = 'created_at'
}

export interface GetHistoryRequestDto {
  /** Тип объекта */
  element_type: HistoryElementType;
  /** ID объекта */
  element_id: string;
  /** Время начала отсчета */
  start_time?: string | null;
  /** Время конца отсчета */
  end_time?: string | null;
  /**
   * Страница
   * @min 1
   */
  page?: number;
  /**
   * Выводить на странице
   * @min 1
   */
  limit?: number;
  /** Поле сортировки */
  sort_field?: HistorySortField;
  /** Порядок сортировки */
  sort_order?: PaginationSortOrder;
}

export enum HistoryMdmCommandType {
  Restart = 'restart',
  UnrollDevice = 'unroll_device',
  DownloadUpdates = 'download_updates',
  InstallUpdates = 'install_updates',
  EnableBluetooth = 'enable_bluetooth',
  DisableBluetooth = 'disable_bluetooth',
  WipeDevice = 'wipe_device',
  LockDevice = 'lock_device',
  SendBlankPush = 'send_blank_push',
  EnableArd = 'enable_ard',
  DisableArd = 'disable_ard',
  GetActivationLockBypassCode = 'get_activation_lock_bypass_code',
  SetFirmwarePassword = 'set_firmware_password',
  SetRecoveryLock = 'set_recovery_lock',
  ClearPasscode = 'clear_passcode',
  EnableLostMode = 'enable_lost_mode',
  DisableLostMode = 'disable_lost_mode'
}

export interface HistoryAdditionalInfoMdmCommand {
  command: HistoryMdmCommandType;
}

export interface HistoryAdditionalInfoSmartGroup {
  smart_group_id: string;
}

export interface HistoryAdditionalInfoStaticGroup {
  static_group_id: string;
}

export interface HistoryAdditionalInfoPolicy {
  policy_id: string;
}

export interface HistoryAdditionalInfoProfile {
  profile_id: string;
}

export interface DeviceInformation {
  device: Device;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  device_capacity: number | null;
  available_device_capacity: number | null;
  is_supervised: boolean | null;
  software_update_device_id: string | null;
  device_name: string | null;
  local_ip: string | null;
  cpu_type: string | null;
  is_apple_silicon: boolean | null;
  main_network_interface: string | null;
  main_network_interface_mac: string | null;
  model_identifier: string | null;
  total_ram_mb: number | null;
  battery_capacity: string | null;
  os_name: string | null;
  os_version: string | null;
  os_build_version: string | null;
  os_rapid_security_response: string | null;
  os_ad_bind: boolean | null;
  firmware_password_enabled: boolean | null;
  firmware_password: string | null;
  ard_enabled: boolean | null;
  sip_enabled: boolean | null;
  activation_lock_enabled: boolean | null;
  activation_lock: string | null;
  x_protect_definitions_version: string | null;
  firewall_enabled: boolean | null;
  autologin_enabled: boolean | null;
  gatekeeper_enabled: boolean | null;
  recovery_lock_enabled: boolean | null;
  recovery_lock: string | null;
  primary_drive_serial: string | null;
  personal_recovery_key: string | null;
  primary_drive_model: string | null;
  primary_drive_smart_status: string | null;
  primary_drive_revision: string | null;
  primary_drive_capacity_mb: number | null;
  filevault_enabled: boolean | null;
  filevault_has_institutional_key: boolean | null;
  filevault_has_personal_key: boolean | null;
  lock_pin: string | null;
  time_zone: string | null;
  is_network_tethered: boolean | null;
  eas_device_identifier: string | null;
  is_do_not_disturb_in_effect: boolean | null;
  supplemental_os_version_extra: string | null;
  build_version: string | null;
  supplemental_build_version: string | null;
  is_cloud_backup_enabled: boolean | null;
  /** @format date-time */
  last_cloud_backup_date: string | null;
  battery_level: string | null;
  wifi_mac: string | null;
  bluetooth_mac: string | null;
  modem_firmware_version: string | null;
  is_device_locator_service_enabled: boolean | null;
  is_mdm_lost_mode_enabled: boolean | null;
  unlock_token: string | null;
  model: string | null;
  model_number: string | null;
  hardware_encryption_caps: number | null;
  passcode_compliant: boolean | null;
  passcode_compliant_with_profiles: boolean | null;
  passcode_lock_grace_period_enforced: number | null;
}

export interface AgentInstance {
  device: Device;
  /** @default null */
  last_remote_address: string | null;
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
}

export interface DeviceUser {
  username: string;
  email: string | null;
  employee_name: string | null;
  phone_number: string | null;
  position: string | null;
  device: Device;
  /** @default [] */
  static_groups: object;
  mobile_devices_count: string;
  computers_count: string;
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
}

export interface Device {
  id: string;
  udid: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  serial_number: string;
  model_name: string | null;
  mdm_enrollment_status: boolean;
  agent_enrollment_status: boolean;
  remove_pending: boolean;
  was_checked_in: boolean;
  /** @format date-time */
  last_seen: string;
  dep_profile_status: string | null;
  os_type: 'iOS' | 'macOS';
  enroll_profile_version: number;
  device_information?: DeviceInformation;
  agent_instance?: AgentInstance;
  /** @default [] */
  applications: object;
  /** @default [] */
  profiles: object;
  /** @default [] */
  provisioning_profiles: object;
  /** @default [] */
  certificates: object;
  /** @default [] */
  user_accounts: object;
  /** @default [] */
  services: object;
  /** @default [] */
  device_extension_attributes: object;
  device_user: DeviceUser | null;
  display_name: string | null;
  model_and_serial: string | null;
}

export enum DeviceHistoryType {
  DeviceAdd = 'device:add',
  DeviceDelete = 'device:delete',
  DeviceEdit = 'device:edit',
  DeviceSmartGroupAdd = 'device:smart-group:add',
  DeviceSmartGroupRemove = 'device:smart-group:remove',
  DeviceStaticGroupAdd = 'device:static-group:add',
  DeviceStaticGroupRemove = 'device:static-group:remove',
  DeviceScopePolicy = 'device:scope:policy',
  DeviceScopeProfile = 'device:scope:profile',
  DeviceScopeApp = 'device:scope:app',
  DeviceMdmCommandSent = 'device:mdm-command:sent',
  DeviceMdmCommandFailed = 'device:mdm-command:failed',
  DeviceTerminalStarted = 'device:terminal:started'
}

export interface HistoryDto {
  id: string;
  /**
   * Создано
   * @format date-time
   */
  created_at: string;
  /**
   * Обновлено
   * @format date-time
   */
  updated_at: string;
  /** Тип объекта */
  element_type: 'device';
  /** ID объекта */
  element_id: string;
  /** Устройство */
  device?: Device | null;
  /** Тип записи */
  history_type: DeviceHistoryType;
  /** Дополнительная информация */
  additional_info?: object | null;
}

export interface GetHistoryResponseDto {
  /** Количество */
  count: number;
  /** Записи истории */
  history: HistoryDto[];
}

export enum DeviceUserCriteria {
  Username = 'Username',
  Email = 'Email',
  EmployeeName = 'EmployeeName',
  PhoneNumber = 'PhoneNumber',
  Position = 'Position',
  Group = 'Group'
}

export interface GetDeviceUsersCriteriaValuesRequestDto {
  criteria: DeviceUserCriteria;
}

export interface SearchDeviceUserByConditionDto {
  criteria: string;
  value: string;
}

export enum DeviceUserSortField {
  Username = 'username',
  Email = 'email',
  EmployeeName = 'employee_name',
  Position = 'position',
  PhoneNumber = 'phone_number',
  ComputersCount = 'computers_count',
  MobileDevicesCount = 'mobile_devices_count'
}

export interface GetDeviceUserListRequestDto {
  search?: string;
  conditions?: SearchDeviceUserByConditionDto[];
  /** @min 1 */
  page?: number;
  /** @min 1 */
  limit?: number;
  sort_field?: DeviceUserSortField;
  sort_order?: PaginationSortOrder;
}

export interface GetDeviceUserListResponseDto {
  count: number;
  users: DeviceUserSimpleDto[];
}

export interface GetDeviceUsersCriteriaResponseDto {
  count: number;
  criteria: DeviceUserCriteria[];
}

export interface DeviceUsersCriteriaValue {
  value: string;
  title: string | null;
}

export interface GetDeviceUsersCriteriaValuesResponseDto {
  values: DeviceUsersCriteriaValue[];
}

export interface CreateDeviceUserRequestDto {
  /**
   * Имя пользователя
   * @maxLength 256
   */
  username: string;
  /** Email пользователя */
  email?: string;
  /**
   * Фактическое имя сотрудника
   * @maxLength 256
   */
  employee_name?: string;
  /** Контактный номер телефона */
  phone_number?: string;
  /**
   * Должность пользователя
   * @maxLength 256
   */
  position?: string;
}

export interface UpdateDeviceUserRequestDto {
  /**
   * Имя пользователя
   * @maxLength 256
   */
  username?: string;
  /** Email пользователя */
  email?: string;
  /**
   * Фактическое имя сотрудника
   * @maxLength 256
   */
  employee_name?: string;
  /** Контактный номер телефона */
  phone_number?: string;
  /** Должность пользователя */
  position?: string;
}

export interface DeleteDeviceUsersDto {
  user_ids: string[];
}

export interface UploadSpreadsheetRequestDto {
  /**
   * Файл таблицы
   * @format binary
   */
  spreadsheet: File;
  merge?: boolean;
}

export interface ScriptBasicDto {
  /** @format uuid */
  id: string;
  /**
   * Создано
   * @format date-time
   */
  created_at: string;
  /**
   * Обновлено
   * @format date-time
   */
  updated_at: string;
  /** Отображаемое имя */
  display_name: string;
  /** Описание */
  description: string | null;
}

export interface GetScriptsResponseDto {
  /** Количество */
  count: number;
  /** Скрипты */
  scripts: ScriptBasicDto[];
}

export interface ScriptFullDto {
  /** @format uuid */
  id: string;
  /**
   * Создано
   * @format date-time
   */
  created_at: string;
  /**
   * Обновлено
   * @format date-time
   */
  updated_at: string;
  /** Отображаемое имя */
  display_name: string;
  /** Описание */
  description: string | null;
  /** Содержимое */
  content: string;
}

export interface CreateScriptRequestDto {
  /**
   * Наименование
   * @maxLength 256
   * @example "Script name"
   */
  name: string;
  /** Тело скрипта */
  script: string;
  /** Описание */
  description?: string;
}

export interface UpdateScriptRequestDto {
  /**
   * Наименование
   * @maxLength 256
   * @example "Script name"
   */
  name?: string;
  /** Тело скрипта */
  script?: string;
  /** Описание */
  description?: string;
}

export enum PoliciesSortField {
  Title = 'title',
  Frequency = 'frequency',
  Trigger = 'trigger',
  Scope = 'scope',
  ScopedDevicesCount = 'scoped_devices_count',
  SuccessfulDistinctRunsCount = 'successful_distinct_runs_count'
}

export interface GetPoliciesRequestDto {
  /**
   * Страница
   * @min 1
   */
  page?: number;
  /**
   * Выводить на странице
   * @min 1
   */
  limit?: number;
  /** Поле сортировки */
  sort_field?: PoliciesSortField;
  /** Порядок сортировки */
  sort_order?: PaginationSortOrder;
}

export interface GetPolicyRunsRequestDto {
  /** ID политики */
  policy_id?: string;
  /**
   * Страница
   * @min 1
   */
  page?: number;
  /**
   * Выводить на странице
   * @min 1
   */
  limit?: number;
}

export enum PolicyTrigger {
  Immediate = 'Immediate',
  Interval = 'Interval',
  CustomEvent = 'CustomEvent'
}

export enum PolicyFrequency {
  Ongoing = 'Ongoing',
  OnceEveryDay = 'OnceEveryDay',
  OnceEveryWeek = 'OnceEveryWeek',
  OnceEveryMonth = 'OnceEveryMonth',
  OncePerComputer = 'OncePerComputer'
}

export interface PolicyConfiguration {
  /** Триггеры */
  triggers: PolicyTrigger[];
  /** Частота */
  frequency: PolicyFrequency;
  /**
   * Интервал
   * @min 5
   * @max 43200
   * @example 30
   */
  interval_amount?: number;
}

export enum ScopeEntryOperationType {
  Include = 'Include',
  Exclude = 'Exclude'
}

export interface ScopeEntrySimpleDto {
  /** ID */
  id: string;
  /** Тип субъекта */
  parent_type: 'Policy' | 'ConfigurationProfile' | 'DeviceApplication';
  /** ID субъекта */
  parent_id: string;
  /** Тип объекта */
  target_type:
    | 'Device'
    | 'StaticGroupDevices'
    | 'SmartGroupDevices'
    | 'DeviceUser'
    | 'StaticGroupDeviceUsers'
    | 'SmartGroupDeviceUsers';
  /** ID объекта */
  target_id: string;
  /** Наименование объекта */
  target_title: string | null;
  /** Тип операции */
  operation_type: ScopeEntryOperationType;
}

export interface PolicySimpleDto {
  /** @format uuid */
  id: string;
  /**
   * Создано
   * @format date-time
   */
  created_at: string;
  /**
   * Обновлено
   * @format date-time
   */
  updated_at: string;
  /** Наименование */
  title: string;
  /** Включена */
  enabled: boolean;
  /** Имеет записи в области действия */
  scoped: boolean;
  /** Конфигурация политики */
  configuration: PolicyConfiguration | null;
  /** Записи в области действия */
  scope_entries?: ScopeEntrySimpleDto[];
  /** Количество успешных воспроизведений */
  successful_distinct_runs_count: number;
  /** Количество устройств в области действия */
  scoped_devices_count: number;
  /** Количесвто неподтвержденных и неудавшихся воспроизведений */
  unacknowledged_failed_policy_run_count: number;
}

export interface GetPoliciesResponseDto {
  /** Количество */
  count: number;
  /** Политики */
  policies: PolicySimpleDto[];
}

export interface PolicyRunSimpleDto {
  /** @format uuid */
  id: string;
  /**
   * Создано
   * @format date-time
   */
  created_at: string;
  /**
   * Обновлено
   * @format date-time
   */
  updated_at: string;
  /**
   * Начата
   * @format date-time
   */
  started_at: string | null;
  /**
   * Закончена
   * @format date-time
   */
  finished_at: string | null;
  /** В процессе */
  in_progress: boolean;
  /** Успешно */
  success: boolean | null;
  /** Подтверждено */
  acknowledged: boolean;
  /** Ожидание отмены */
  cancel_pending: boolean;
  /** Отменено */
  canceled: boolean;
  /** Устройство */
  device: DeviceSimpleDto | null;
}

export interface GetPolicyRunsResponseDto {
  /** Количество */
  count: number;
  /** Воспроизведения политики */
  policy_runs: PolicyRunSimpleDto[];
}

export interface AgentCommandSimpleDto {
  /** @format uuid */
  id: string;
  /**
   * Создано
   * @format date-time
   */
  created_at: string;
  /**
   * Обновлено
   * @format date-time
   */
  updated_at: string;
  /** Тип запроса */
  request_type: 'GetDeviceInformation' | 'ModifyPackage' | 'UninstallAgent' | 'Custom';
  /** Скрипт */
  script: string | null;
  /** Строки вывода */
  output_lines: number;
  /**
   * Аргументы
   * @default null
   */
  arguments: string[] | null;
  /** В очереди */
  queued: boolean;
  /** В процессе */
  in_progress: boolean;
  /** Успешно */
  success: boolean | null;
  /** Код завершения */
  exit_code: number | null;
  /** Результат */
  result: string[] | null;
  /** Ошибка результата */
  result_error: string[] | null;
  /** Время истекло */
  timed_out: boolean;
  /** Отмена ожидания */
  cancel_pending: boolean;
  /** Отменен */
  canceled: boolean;
}

export interface PolicyRunStepSimpleDto {
  /** @format uuid */
  id: string;
  /**
   * Создано
   * @format date-time
   */
  created_at: string;
  /**
   * Обновлено
   * @format date-time
   */
  updated_at: string;
  /** В процессе */
  in_progress: boolean;
  /** Успешно */
  success: boolean | null;
  /** ID команды */
  command_id: string | null;
  /** Команда агента */
  agent_command: AgentCommandSimpleDto | null;
  /** Конфигурация */
  configuration: object | null;
  /** Тип */
  type: 'agent' | 'mdm';
  /** Тип команды */
  command_type: 'RunCustomScript' | 'ModifyPackage' | 'GetDeviceInformation' | 'RestartComputer';
}

export interface PolicyRunFullDto {
  /** @format uuid */
  id: string;
  /**
   * Создано
   * @format date-time
   */
  created_at: string;
  /**
   * Обновлено
   * @format date-time
   */
  updated_at: string;
  /**
   * Начата
   * @format date-time
   */
  started_at: string | null;
  /**
   * Закончена
   * @format date-time
   */
  finished_at: string | null;
  /** В процессе */
  in_progress: boolean;
  /** Успешно */
  success: boolean | null;
  /** Подтверждено */
  acknowledged: boolean;
  /** Ожидание отмены */
  cancel_pending: boolean;
  /** Отменено */
  canceled: boolean;
  /** Устройство */
  device: DeviceSimpleDto | null;
  /** Шаги */
  steps: PolicyRunStepSimpleDto[];
}

export interface PolicyPayloadScript {
  script_id: string;
  script_arguments?: string[];
  script_title?: string;
}

export enum PolicyPayloadPackageDistributionType {
  Default = 'Default'
}

export enum PolicyPayloadPackageModifyType {
  Install = 'Install',
  Cache = 'Cache',
  InstallCached = 'InstallCached'
}

export interface PolicyPayloadPackagesItem {
  modify_type: PolicyPayloadPackageModifyType;
  package_id: string;
  package_title?: string;
}

export interface PolicyPayloadPackages {
  distribution_type: PolicyPayloadPackageDistributionType;
  items: PolicyPayloadPackagesItem[];
}

export interface PolicyPayloadMaintenance {
  update_inventory: boolean;
}

export interface PolicyPayload {
  scripts?: PolicyPayloadScript[];
  packages?: PolicyPayloadPackages;
  maintenance?: PolicyPayloadMaintenance;
}

export interface PolicyFullDto {
  /** @format uuid */
  id: string;
  /**
   * Создано
   * @format date-time
   */
  created_at: string;
  /**
   * Обновлено
   * @format date-time
   */
  updated_at: string;
  /** Наименование */
  title: string;
  /** Включена */
  enabled: boolean;
  /** Имеет записи в области действия */
  scoped: boolean;
  /** Конфигурация политики */
  configuration: PolicyConfiguration | null;
  /** Записи в области действия */
  scope_entries?: ScopeEntrySimpleDto[];
  /** Количество успешных воспроизведений */
  successful_distinct_runs_count: number;
  /** Количество устройств в области действия */
  scoped_devices_count: number;
  /** Количесвто неподтвержденных и неудавшихся воспроизведений */
  unacknowledged_failed_policy_run_count: number;
  /** Нагрузка */
  payload: PolicyPayload | null;
}

export interface CreatePolicyScopeRequestDto {
  /** Тип объекта */
  type:
    | 'Device'
    | 'StaticGroupDevices'
    | 'SmartGroupDevices'
    | 'DeviceUser'
    | 'StaticGroupDeviceUsers'
    | 'SmartGroupDeviceUsers';
  /** Тип операции включения */
  operation: ScopeEntryOperationType;
  /** ID объекта */
  id: string;
}

export interface CreatePolicyRequestDto {
  /**
   * Наименование
   * @maxLength 256
   * @example "Policy title"
   */
  title: string;
  /** Включена */
  enabled?: boolean;
  /** Имеет записи в области действия */
  scoped?: boolean;
  /** Конфигурация политики */
  configuration: PolicyConfiguration;
  /** Нагрзука политики */
  payload?: PolicyPayload | null;
  /** Записи в области действия */
  scope_entries?: CreatePolicyScopeRequestDto[];
}

export enum UpdatePolicyRerun {
  All = 'all',
  ScopeNew = 'scope_new'
}

export interface UpdatePolicyRequestDto {
  /**
   * Наименование
   * @maxLength 256
   * @example "Policy title"
   */
  title?: string;
  /** Включено */
  enabled?: boolean;
  /** Имеет записи в области действия */
  scoped?: boolean;
  /** Конфигурация политики */
  configuration?: PolicyConfiguration;
  /** Нагрузка политики */
  payload?: PolicyPayload | null;
  /** Записи в области действия */
  scope_entries?: CreatePolicyScopeRequestDto[];
  /** Порядок повторного воспроизведения */
  rerun?: UpdatePolicyRerun;
}

export enum ScopeOptionType {
  Device = 'Device',
  StaticGroupDevices = 'StaticGroupDevices',
  SmartGroupDevices = 'SmartGroupDevices',
  DeviceUser = 'DeviceUser',
  StaticGroupDeviceUsers = 'StaticGroupDeviceUsers',
  SmartGroupDeviceUsers = 'SmartGroupDeviceUsers'
}

export interface GetScopeOptionsRequestDto {
  /**
   * Страница
   * @min 1
   */
  page?: number;
  /**
   * Выводить на странице
   * @min 1
   */
  limit?: number;
  /** Порядок сортировки */
  sort_order?: PaginationSortOrder;
  /** Тип добавляемого в область действия объекта */
  type: ScopeOptionType;
  /** Тип целевой ОС */
  target_os_type?: DeviceOsType;
}

export interface ScopeOptionDto {
  /** Отображаемое имя */
  type: ScopeOptionType;
  id: string;
  display_name: string;
}

export interface GetScopeOptionsDto {
  /** Варианты объектов */
  options: ScopeOptionDto[];
  /** Количество */
  count: number;
}

export enum StaticGroupDevicesSortField {
  Title = 'title',
  DevicesCount = 'devices_count',
  Id = 'id',
  UpdatedAt = 'updated_at',
  CreatedAt = 'created_at'
}

export interface GetStaticGroupsDevicesRequestDto {
  /**
   * Страница
   * @min 1
   */
  page?: number;
  /**
   * Выводить на странице
   * @min 1
   */
  limit?: number;
  /** Поле сортировки */
  sort_field?: StaticGroupDevicesSortField;
  /** Порядок сортировки */
  sort_order?: PaginationSortOrder;
  /** Тип ОС */
  os_type: DeviceOsType;
}

export enum StaticGroupDeviceUsersSortField {
  Title = 'title',
  DeviceUsersCount = 'device_users_count',
  Id = 'id',
  UpdatedAt = 'updated_at',
  CreatedAt = 'created_at'
}

export interface GetStaticGroupsDeviceUsersRequestDto {
  /**
   * Страница
   * @min 1
   */
  page?: number;
  /**
   * Выводить на странице
   * @min 1
   */
  limit?: number;
  /** Поле сортировки */
  sort_field?: StaticGroupDeviceUsersSortField;
  /** Порядок сортировки */
  sort_order?: PaginationSortOrder;
}

export enum SmartGroupDevicesSortField {
  Title = 'title',
  DevicesCount = 'devices_count',
  Id = 'id',
  UpdatedAt = 'updated_at',
  CreatedAt = 'created_at'
}

export interface GetSmartGroupsDevicesRequestDto {
  /**
   * Страница
   * @min 1
   */
  page?: number;
  /**
   * Выводить на странице
   * @min 1
   */
  limit?: number;
  /** Поле сортировки */
  sort_field?: SmartGroupDevicesSortField;
  /** Порядок сортировки */
  sort_order?: PaginationSortOrder;
  /** Загрузить все */
  get_all?: boolean;
  /** Тип ОС */
  os_type: DeviceOsType;
}

export enum SmartGroupDeviceUsersSortField {
  Title = 'title',
  DeviceUsersCount = 'device_users_count',
  Id = 'id',
  UpdatedAt = 'updated_at',
  CreatedAt = 'created_at'
}

export interface GetSmartGroupsDeviceUsersRequestDto {
  /**
   * Страница
   * @min 1
   */
  page?: number;
  /**
   * Выводить на странице
   * @min 1
   */
  limit?: number;
  /** Поле сортировки */
  sort_field?: SmartGroupDeviceUsersSortField;
  /** Порядок сортировки */
  sort_order?: PaginationSortOrder;
  /** Загрузить все */
  get_all?: boolean;
}

export interface StaticGroupDevicesSimpleDto {
  /** @format uuid */
  id: string;
  /**
   * Создано
   * @format date-time
   */
  created_at: string;
  /**
   * Обновлено
   * @format date-time
   */
  updated_at: string;
  /** Наименование */
  title: string;
  /** Количество */
  devices_count: number;
  /** Тип ОС */
  os_type: 'iOS' | 'macOS';
}

export interface GetStaticGroupsDevicesResponseDto {
  /** Количество */
  count: number;
  /** Группы */
  groups: StaticGroupDevicesSimpleDto[];
}

export interface GetDevicesFromSmartGroupRequestDto {
  /**
   * Номер страницы списка устройств.
   * @min 1
   */
  page?: number;
  /**
   * Количество выыводимых на одной странице устройств.
   * @min 1
   */
  limit?: number;
  /** Поле сортировки устройств */
  sort_field?: DevicesSortField;
  /** Порядок сортировки устройств */
  sort_order?: PaginationSortOrder;
  /** Выводить все устройства */
  get_all?: boolean;
}

export interface GetSmartGroupDevicesCSVRequestDto {
  /** ID смарт-группы */
  group_id: string;
}

export interface GroupSimpleDto {
  /**
   * ID
   * @format uuid
   */
  id: string;
  /** Наименование */
  title: string;
  /** Тип группы */
  type: 'StaticDevices' | 'SmartDevices' | 'StaticDeviceUsers' | 'SmartDeviceUsers';
}

export interface GetGroupsForDeviceDto {
  /** Группы */
  groups: GroupSimpleDto[];
}

export interface StaticGroupDevicesFullDto {
  /** @format uuid */
  id: string;
  /**
   * Создано
   * @format date-time
   */
  created_at: string;
  /**
   * Обновлено
   * @format date-time
   */
  updated_at: string;
  /** Наименование */
  title: string;
  /** Количество */
  devices_count: number;
  /** Тип ОС */
  os_type: 'iOS' | 'macOS';
  /** Устройства */
  devices: DeviceSimpleDto[];
}

export interface CreateStaticGroupDevicesRequestDto {
  /** Тип ОС */
  os_type: DeviceOsType;
  /**
   * Наименование
   * @maxLength 256
   */
  title: string;
  /** Устройства */
  devices?: string[];
}

export interface StaticGroupDevices {
  title: string;
  os_type: 'iOS' | 'macOS';
  /** @default [] */
  devices: object;
  devices_count: number;
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
}

export interface UpdateStaticGroupDevicesRequestDto {
  /**
   * Наименование
   * @maxLength 256
   */
  title?: string;
  /** Устройства */
  devices?: string[];
}

export interface StaticGroupDeviceUsersSimpleDto {
  /** @format uuid */
  id: string;
  /**
   * Создано
   * @format date-time
   */
  created_at: string;
  /**
   * Обновлено
   * @format date-time
   */
  updated_at: string;
  /** Наименование */
  title: string;
  /** Количество */
  device_users_count: number;
}

export interface GetStaticGroupsDeviceUsersResponseDto {
  /** Количество */
  count: number;
  /** Группы */
  groups: StaticGroupDeviceUsersSimpleDto[];
}

export interface StaticGroupDeviceUsersFullDto {
  /** @format uuid */
  id: string;
  /**
   * Создано
   * @format date-time
   */
  created_at: string;
  /**
   * Обновлено
   * @format date-time
   */
  updated_at: string;
  /** Наименование */
  title: string;
  /** Количество */
  device_users_count: number;
  /** ID пользователей устройств */
  device_user_ids: string[];
}

export interface CreateStaticGroupDeviceUsersRequestDto {
  /**
   * Название
   * @maxLength 256
   */
  title: string;
  /** Пользователи устройств */
  device_users?: string[];
}

export interface UpdateStaticGroupDeviceUsersRequestDto {
  /**
   * Название
   * @maxLength 256
   */
  title?: string;
  /** Пользователи устройств */
  device_users?: string[];
}

export interface SmartGroupDevicesSimpleDto {
  /** @format uuid */
  id: string;
  /**
   * Создано
   * @format date-time
   */
  created_at: string;
  /**
   * Обновлено
   * @format date-time
   */
  updated_at: string;
  /** Наименование */
  title: string;
  /** Количество */
  devices_count: number;
  /** Тип ОС */
  os_type: 'iOS' | 'macOS';
}

export interface GetSmartGroupsDevicesResponseDto {
  /** Количество */
  count: number;
  /** Группы */
  groups: SmartGroupDevicesSimpleDto[];
}

export enum ConditionConjunctive {
  And = 'and',
  Or = 'or'
}

export interface SmartGroupDevicesConditionStrippedDto {
  /** Критерий */
  criteria: string;
  /** Оператор */
  operator: '=' | '!=' | 'like' | 'not like' | '>' | '>=' | '<' | '<=';
  /** Значение */
  value: string;
  /** Логическая операция */
  conjunctive: ConditionConjunctive | null;
  /** Открывающаяся скобка */
  start_bracket: boolean;
  /** Закрывающаяся скобка */
  end_bracket: boolean;
}

export interface SmartGroupDevicesFullDto {
  /** @format uuid */
  id: string;
  /**
   * Создано
   * @format date-time
   */
  created_at: string;
  /**
   * Обновлено
   * @format date-time
   */
  updated_at: string;
  /** Наименование */
  title: string;
  /** Количество */
  devices_count: number;
  /** Тип ОС */
  os_type: 'iOS' | 'macOS';
  /** Условия */
  conditions: SmartGroupDevicesConditionStrippedDto[];
  /** Устройства */
  devices: DeviceSimpleDto[];
}

export interface GetDevicesFromSmartGroupResponseDto {
  /** Количество */
  count: number;
  /** Устройства */
  devices: DeviceSimpleDto[];
}

export interface CreateSmartGroupDevicesConditionRequestDto {
  /** Критерий */
  criteria: string;
  /** Оператор */
  operator: '=' | '!=' | 'like' | 'not like' | '>' | '>=' | '<' | '<=';
  /** Значение */
  value: string;
  /** Логическая операция */
  conjunctive?: 'and' | 'or' | null;
  /** Открывающаяся скобка */
  start_bracket?: boolean;
  /** Закрывающаяся скобка */
  end_bracket?: boolean;
}

export interface CreateSmartGroupDevicesRequestDto {
  /** Тип ОС */
  os_type: DeviceOsType;
  /**
   * Наименование
   * @maxLength 256
   */
  title: string;
  /** Условия */
  conditions?: CreateSmartGroupDevicesConditionRequestDto[];
}

export interface SmartGroupDevices {
  title: string;
  os_type: 'iOS' | 'macOS';
  /** @default [] */
  conditions: object;
  /** @default [] */
  devices: object;
  devices_count: number;
  is_wildcard?: boolean;
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
}

export interface UpdateSmartGroupDevicesRequestDto {
  /**
   * Наименование
   * @maxLength 256
   */
  title?: string;
  /** Условия */
  conditions?: CreateSmartGroupDevicesConditionRequestDto[];
}

export interface SmartGroupDeviceUsersSimpleDto {
  /** @format uuid */
  id: string;
  /**
   * Создано
   * @format date-time
   */
  created_at: string;
  /**
   * Обновлено
   * @format date-time
   */
  updated_at: string;
  /** Наименование */
  title: string;
  /** Количество */
  device_users_count: number;
}

export interface GetSmartGroupsDeviceUsersResponseDto {
  /** Количество */
  count: number;
  /** Группы */
  groups: SmartGroupDeviceUsersSimpleDto[];
}

export enum ConditionDeviceUserOperator {
  Value = '=',
  Value1 = '!=',
  Like = 'like',
  NotLike = 'not like',
  MemberOf = 'member of',
  NotMemberOf = 'not member of'
}

export interface SmartGroupDeviceUsersConditionStrippedDto {
  /** Критерий */
  criteria: DeviceUserCriteria;
  /** Оператор */
  operator: ConditionDeviceUserOperator;
  /** Значение */
  value: string;
  /** Название */
  title: string | null;
  /** Логическая операция */
  conjunctive: ConditionConjunctive | null;
  /** Открывающаяся скобка */
  start_bracket: boolean;
  /** Закрывающаяся скобка */
  end_bracket: boolean;
}

export interface SmartGroupDeviceUsersFullDto {
  /** @format uuid */
  id: string;
  /**
   * Создано
   * @format date-time
   */
  created_at: string;
  /**
   * Обновлено
   * @format date-time
   */
  updated_at: string;
  /** Наименование */
  title: string;
  /** Количество */
  device_users_count: number;
  /** Условия */
  conditions: SmartGroupDeviceUsersConditionStrippedDto[];
  /** Пользователи устройств */
  device_users: DeviceUserSimpleDto[];
}

export interface CreateSmartGroupDeviceUsersConditionRequestDto {
  /** Критерий */
  criteria: DeviceUserCriteria;
  /** Оператор */
  operator: '=' | '!=' | 'like' | 'not like' | 'member of' | 'not member of';
  /** Значение */
  value: string;
  /** Логическая операция */
  conjunctive?: 'and' | 'or' | null;
  /** Открывающаяся скобка */
  start_bracket?: boolean;
  /** Закрывающаяся скобка */
  end_bracket?: boolean;
}

export interface CreateSmartGroupDeviceUsersRequestDto {
  /**
   * Наименование
   * @maxLength 256
   */
  title: string;
  /** Условия */
  conditions?: CreateSmartGroupDeviceUsersConditionRequestDto[];
}

export interface UpdateSmartGroupDeviceUsersRequestDto {
  /**
   * Название
   * @maxLength 256
   */
  title?: string;
  /** Условия */
  conditions?: CreateSmartGroupDeviceUsersConditionRequestDto[];
}

export interface PackageSimpleDto {
  /** @format uuid */
  id: string;
  /**
   * Создано
   * @format date-time
   */
  created_at: string;
  /**
   * Обновлено
   * @format date-time
   */
  updated_at: string;
  /** Отображаемое имя */
  display_name: string;
  /** Имя файла */
  filename: string;
}

export interface GetPackagesResponseDto {
  /** Количество */
  count: number;
  /** Пакеты */
  packages: PackageSimpleDto[];
}

export interface CreatePackageDto {
  /**
   * Отображаемое имя
   * @maxLength 256
   */
  display_name: string;
  /**
   * Имя файла пакета
   * @pattern /.+\.pkg$/
   * @example "application.pkg"
   */
  filename: string;
}

export interface UpdatePackageDto {
  /**
   * Отображаемое имя
   * @maxLength 256
   */
  display_name?: string;
  /**
   * Имя файла
   * @pattern /.+\.pkg$/
   * @example "application.pkg"
   */
  filename?: string;
}

export enum PackageDistributionPointType {
  Smb = 'smb',
  Http = 'http'
}

export interface PackagePointSimpleDto {
  /** @format uuid */
  id: string;
  /**
   * Создано
   * @format date-time
   */
  created_at: string;
  /**
   * Обновлено
   * @format date-time
   */
  updated_at: string;
  /** Отображаемое имя */
  display_name: string;
  /** Тип точки распространения пакетов */
  type: PackageDistributionPointType;
  /** Адрес сервера */
  server: string;
  /** Название точки распространения пакетов */
  share_name: string | null;
  /** Порт */
  port: number;
  /** Логин */
  read_username: string;
}

export interface GetPackagePointsResponseDto {
  /** Количество */
  count: number;
  /** Точки распростарнения пакетов */
  package_points: PackagePointSimpleDto[];
}

export interface PackagePointFullDto {
  /** @format uuid */
  id: string;
  /**
   * Создано
   * @format date-time
   */
  created_at: string;
  /**
   * Обновлено
   * @format date-time
   */
  updated_at: string;
  /** Отображаемое имя */
  display_name: string;
  /** Тип точки распространения пакетов */
  type: PackageDistributionPointType;
  /** Адрес сервера */
  server: string;
  /** Название точки распространения пакетов */
  share_name: string | null;
  /** Порт */
  port: number;
  /** Логин */
  read_username: string;
  /** Пароль */
  read_password: string;
}

export interface CreatePackagePointDto {
  /**
   * Отображаемое имя
   * @maxLength 256
   */
  display_name: string;
  /** Тип точки распространения пакетов */
  type: PackageDistributionPointType;
  /** Адрес сервера */
  server: string;
  /**
   * Название точки распространения пакетов
   * @maxLength 256
   */
  share_name?: string;
  /**
   * Порт
   * @min 0
   * @max 65535
   */
  port?: number | null;
  /** Логин */
  read_username?: string;
  /** Пароль */
  read_password?: string;
}

export interface UpdatePackagePointDto {
  /**
   * Отображаемое имя
   * @maxLength 256
   */
  display_name?: string;
  /** Тип точки распространения пакетов */
  type?: PackageDistributionPointType;
  /** Адрес сервера */
  server?: string;
  /**
   * Название точки распространения пакетов
   * @maxLength 256
   */
  share_name?: string;
  /**
   * Порт
   * @min 0
   * @max 65535
   */
  port?: number | null;
  /** Логин */
  read_username?: string;
  /** Пароль */
  read_password?: string;
}

export enum ConfigProfileLogField {
  DeviceInformationDeviceName = 'device_information.device_name',
  DeviceSerialNumber = 'device.serial_number',
  CreatedAt = 'created_at',
  Status = 'status'
}

export interface GetConfigProfileLogsRequestDto {
  /**
   * Страница
   * @min 1
   */
  page?: number;
  /**
   * Выводить на странице
   * @min 1
   */
  limit?: number;
  /** Поле сортировки */
  sort_field?: ConfigProfileLogField;
  /** Порядок сортировки */
  sort_order?: PaginationSortOrder;
}

export interface ConfigProfileLogDto {
  /** @format uuid */
  id: string;
  /**
   * Создано
   * @format date-time
   */
  created_at: string;
  /**
   * Обновлено
   * @format date-time
   */
  updated_at: string;
  /** Устройство */
  device: DeviceSimpleDto;
  /** Статус */
  status: 'in_progress' | 'success' | 'failed';
}

export interface GetConfigProfileLogsResponseDto {
  /** Количество */
  count: number;
  /** Логи */
  logs: ConfigProfileLogDto[];
}

export enum DeviceType {
  Computer = 'computer',
  Mobile = 'mobile'
}

export enum ConfigProfilesSortField {
  DisplayName = 'display_name',
  CompletedProfileInstancesCount = 'completed_profile_instances_count',
  PendingProfileInstancesCount = 'pending_profile_instances_count',
  FailedProfileInstancesCount = 'failed_profile_instances_count',
  Scoped = 'scoped'
}

export interface GetConfigProfilesRequestDto {
  /** Тип устройства */
  device_type: DeviceType;
  /**
   * Страница
   * @min 1
   */
  page?: number;
  /**
   * Выводить на странице
   * @min 1
   */
  limit?: number;
  /** Поле сортировки */
  sort_field?: ConfigProfilesSortField;
  /** Порядок сортировки */
  sort_order?: PaginationSortOrder;
}

export interface ConfigProfileSimpleDto {
  /** @format uuid */
  id: string;
  /**
   * Создано
   * @format date-time
   */
  created_at: string;
  /**
   * Обновлено
   * @format date-time
   */
  updated_at: string;
  /** ID профиля */
  profile_id: string;
  /** Отображаемое имя */
  display_name: string;
  /** Имя организации */
  organization_name: string | null;
  /** Описание */
  description: string | null;
  /** Имеет записи в области действия */
  scoped: boolean;
  /** Импортирован */
  imported: boolean;
  /** Ожидает удаления */
  remove_pending: boolean;
  /** Записи в области действия */
  scope_entries?: ScopeEntrySimpleDto[];
  /** Количество успешных установок */
  completed_profile_instances_count: number;
  /** Количество ожижадающих установок */
  pending_profile_instances_count: number;
  /** Количество неудачных установок */
  failed_profile_instances_count: number;
}

export interface GetConfigProfilesDto {
  /** Количество */
  count: number;
  /** Профили */
  config_profiles: ConfigProfileSimpleDto[];
}

export interface ConfigProfileRestrictions {
  allowActivityContinuation?: boolean;
  allowAddingGameCenterFriends?: boolean;
  allowAirPlayIncomingRequests?: boolean;
  allowAirPrint?: boolean;
  allowAirPrintCredentialsStorage?: boolean;
  allowAirPrintiBeaconDiscovery?: boolean;
  allowAppCellularDataModification?: boolean;
  allowAppClips?: boolean;
  allowAppInstallation?: boolean;
  allowAppRemoval?: boolean;
  allowApplePersonalizedAdvertising?: boolean;
  allowAssistant?: boolean;
  allowAssistantWhileLocked?: boolean;
  allowAutoCorrection?: boolean;
  allowAutoUnlock?: boolean;
  allowAutomaticAppDownloads?: boolean;
  allowBluetoothModification?: boolean;
  allowBookstore?: boolean;
  allowBookstoreErotica?: boolean;
  allowCamera?: boolean;
  allowCellularPlanModification?: boolean;
  allowChat?: boolean;
  allowCloudBackup?: boolean;
  allowCloudDocumentSync?: boolean;
  allowCloudPhotoLibrary?: boolean;
  allowContinuousPathKeyboard?: boolean;
  allowDefinitionLookup?: boolean;
  allowDeviceNameModification?: boolean;
  allowDeviceSleep?: boolean;
  allowDictation?: boolean;
  allowESIMModification?: boolean;
  allowEnablingRestrictions?: boolean;
  allowEnterpriseAppTrust?: boolean;
  allowEnterpriseBookBackup?: boolean;
  allowEnterpriseBookMetadataSync?: boolean;
  allowEraseContentAndSettings?: boolean;
  allowExplicitContent?: boolean;
  allowFilesNetworkDriveAccess?: boolean;
  allowFilesUSBDriveAccess?: boolean;
  allowFindMyDevice?: boolean;
  allowFindMyFriends?: boolean;
  allowFingerprintForUnlock?: boolean;
  allowFingerprintModification?: boolean;
  allowGameCenter?: boolean;
  allowGlobalBackgroundFetchWhenRoaming?: boolean;
  allowInAppPurchases?: boolean;
  allowKeyboardShortcuts?: boolean;
  allowManagedAppsCloudSync?: boolean;
  allowMultiplayerGaming?: boolean;
  allowMusicService?: boolean;
  allowNews?: boolean;
  allowNotificationsModification?: boolean;
  allowOpenFromManagedToUnmanaged?: boolean;
  allowOpenFromUnmanagedToManaged?: boolean;
  allowPairedWatch?: boolean;
  allowPassbookWhileLocked?: boolean;
  allowPasscodeModification?: boolean;
  allowPasswordAutoFill?: boolean;
  allowPasswordProximityRequests?: boolean;
  allowPasswordSharing?: boolean;
  allowPersonalHotspotModification?: boolean;
  allowPhotoStream?: boolean;
  allowPredictiveKeyboard?: boolean;
  allowProximitySetupToNewDevice?: boolean;
  allowRadioService?: boolean;
  allowRemoteAppPairing?: boolean;
  allowRemoteScreenObservation?: boolean;
  allowSafari?: boolean;
  allowScreenShot?: boolean;
  allowSharedStream?: boolean;
  allowSpellCheck?: boolean;
  allowSpotlightInternetResults?: boolean;
  allowSystemAppRemoval?: boolean;
  allowUIAppInstallation?: boolean;
  allowUIConfigurationProfileInstallation?: boolean;
  allowUSBRestrictedMode?: boolean;
  allowUnpairedExternalBootToRecovery?: boolean;
  allowUntrustedTLSPrompt?: boolean;
  allowVPNCreation?: boolean;
  allowVideoConferencing?: boolean;
  allowVoiceDialing?: boolean;
  allowWallpaperModification?: boolean;
  allowiTunes?: boolean;
  blacklistedAppBundleIDs?: string[];
  enforcedSoftwareUpdateDelay?: number;
  forceAirDropUnmanaged?: boolean;
  forceAirPlayIncomingRequestsPairingPassword?: boolean;
  forceAirPlayOutgoingRequestsPairingPassword?: boolean;
  forceAirPrintTrustedTLSRequirement?: boolean;
  forceAssistantProfanityFilter?: boolean;
  forceAuthenticationBeforeAutoFill?: boolean;
  forceAutomaticDateAndTime?: boolean;
  forceClassroomAutomaticallyJoinClasses?: boolean;
  forceClassroomRequestPermissionToLeaveClasses?: boolean;
  forceClassroomUnpromptedAppAndDeviceLock?: boolean;
  forceClassroomUnpromptedScreenObservation?: boolean;
  forceDelayedSoftwareUpdates?: boolean;
  forceEncryptedBackup?: boolean;
  forceITunesStorePasswordEntry?: boolean;
  forceLimitAdTracking?: boolean;
  forceWatchWristDetection?: boolean;
  forceWiFiPowerOn?: boolean;
  forceWiFiWhitelisting?: boolean;
  ratingApps?: number;
  ratingMovies?: number;
  ratingRegion?: string;
  ratingTVShows?: number;
  safariAcceptCookies?: number;
  safariAllowAutoFill?: boolean;
  safariAllowJavaScript?: boolean;
  safariAllowPopups?: boolean;
  safariForceFraudWarning?: boolean;
}

export interface ConfigProfilePasswords {
  allowSimple?: boolean;
  forcePIN?: boolean;
  maxFailedAttempts?: number;
  maxGracePeriod?: number;
  maxInactivity?: number;
  maxPINAgeInDays?: number;
  minComplexChars?: number;
  minLength?: number;
  pinHistory?: number;
  requireAlphanumeric?: boolean;
}

export interface ConfigProfileDomains {
  EmailDomains?: string[];
  SafariPasswordAutoFillDomains?: string[];
  WebDomains?: string[];
}

export interface ConfigProfileHttpProxy {
  ProxyUsername?: string;
  ProxyPassword?: string;
  ProxyServer?: string;
  ProxyServerPort?: number;
  ProxyCaptiveLoginAllowed?: boolean;
  ProxyPACFallbackAllowed?: boolean;
  ProxyPACURL?: string;
  ProxyType?: 'Auto' | 'Manual';
}

export interface ConfigProfileDnsProxy {
  AppBundleIdentifier?: string;
  ProviderBundleIdentifier?: string;
  ProviderBundleConfiguration?: object;
}

export interface ConfigProfileWebContentFilterWhitelistedBookmark {
  Title: string;
  URL: string;
}

export interface ConfigProfileWebContentFilter {
  FilterType?: 'BuiltIn' | 'Plugin';
  AutoFilterEnabled?: boolean;
  FilterBrowsers?: boolean;
  FilterSockets?: boolean;
  Organization?: string;
  Password?: string;
  PluginBundleID?: string;
  ServerAddress?: string;
  UserDefinedName?: string;
  UserName?: string;
  VendorConfig?: object;
  BlacklistedURLs?: string[];
  PermittedURLs?: string[];
  WhitelistedBookmarks?: ConfigProfileWebContentFilterWhitelistedBookmark[];
}

export interface ConfigProfilePayload {
  /** Ограничения */
  restrictions?: ConfigProfileRestrictions;
  /** Пароли */
  passwords?: ConfigProfilePasswords;
  /** Домены */
  domains?: ConfigProfileDomains;
  /** HTTP прокси */
  http_proxy?: ConfigProfileHttpProxy;
  /** DNS прокси */
  dns_proxy?: ConfigProfileDnsProxy;
  /** Фильтр веб-контента */
  web_content_filter?: ConfigProfileWebContentFilter;
}

export interface ConfigProfileInstanceSimpleDto {
  /** @format uuid */
  id: string;
  /**
   * Создано
   * @format date-time
   */
  created_at: string;
  /**
   * Обновлено
   * @format date-time
   */
  updated_at: string;
  /** ID устройства */
  device_id: string;
  /** В процессе */
  in_progress: boolean;
  /** Ожидает удаления */
  remove_pending: boolean;
  /** Успешно */
  success: boolean | null;
}

export interface ConfigProfileFullDto {
  /** @format uuid */
  id: string;
  /**
   * Создано
   * @format date-time
   */
  created_at: string;
  /**
   * Обновлено
   * @format date-time
   */
  updated_at: string;
  /** ID профиля */
  profile_id: string;
  /** Отображаемое имя */
  display_name: string;
  /** Имя организации */
  organization_name: string | null;
  /** Описание */
  description: string | null;
  /** Имеет записи в области действия */
  scoped: boolean;
  /** Импортирован */
  imported: boolean;
  /** Ожидает удаления */
  remove_pending: boolean;
  /** Записи в области действия */
  scope_entries?: ScopeEntrySimpleDto[];
  /** Количество успешных установок */
  completed_profile_instances_count: number;
  /** Количество ожижадающих установок */
  pending_profile_instances_count: number;
  /** Количество неудачных установок */
  failed_profile_instances_count: number;
  /** Нагрузка */
  payload: ConfigProfilePayload | null;
  /** Имопртированная нагрузка */
  imported_payload: string | null;
  /** Экземпляры */
  instances: ConfigProfileInstanceSimpleDto[];
}

export interface CreateConfigProfileScopeRequestDto {
  type:
    | 'Device'
    | 'StaticGroupDevices'
    | 'SmartGroupDevices'
    | 'DeviceUser'
    | 'StaticGroupDeviceUsers'
    | 'SmartGroupDeviceUsers';
  operation: ScopeEntryOperationType;
  id: string;
}

export interface CreateConfigProfileDto {
  /**
   * Отображаемое имя
   * @maxLength 256
   */
  display_name: string;
  /** Тип устройства */
  device_type: DeviceType;
  /** Описание */
  description?: string;
  /** Имя организации */
  organization_name?: string;
  /** Имеет записи в области действия */
  scoped?: boolean;
  /** Нагрузка */
  payload: ConfigProfilePayload | null;
  /** Записи в области действия */
  scope_entries?: CreateConfigProfileScopeRequestDto[];
}

export interface ImportConfigProfileDto {
  /** Тип устройства */
  device_type: DeviceType;
  /**
   * Конфигурация
   * @format binary
   */
  config: File;
  /** Имеет записи в области действия */
  scoped?: boolean;
  /** Записи в области действия */
  scope_entries?: CreatePolicyScopeRequestDto[];
}

export interface UpdateConfigProfileDto {
  /**
   * Отображаемое имя
   * @maxLength 256
   */
  display_name?: string;
  /** Описание */
  description?: string;
  /** Имя организации */
  organization_name?: string;
  /** Имеет записи в области действия */
  scoped?: boolean;
  /** Нагрузка */
  payload?: object | null;
  /** Записи в области действия */
  scope_entries?: CreateConfigProfileScopeRequestDto[];
}

export interface UpdateByImportConfigProfileDto {
  /**
   * Конфигурация
   * @format binary
   */
  config: File;
  /** Имеет записи в области действия */
  scoped?: boolean;
  /** Записи в области действия */
  scope_entries?: CreatePolicyScopeRequestDto[];
}

export interface AppVersionDto {
  /** Версия */
  version: string;
}

export enum CountryCode {
  Afghanistan = 'Afghanistan',
  AlandIslands = 'AlandIslands',
  Albania = 'Albania',
  Algeria = 'Algeria',
  AmericanSamoa = 'American Samoa',
  Andorra = 'Andorra',
  Angola = 'Angola',
  Anguilla = 'Anguilla',
  Antarctica = 'Antarctica',
  AntiguaAndBarbuda = 'Antigua and Barbuda',
  Argentina = 'Argentina',
  Armenia = 'Armenia',
  Aruba = 'Aruba',
  Australia = 'Australia',
  Austria = 'Austria',
  Azerbaijan = 'Azerbaijan',
  Bahamas = 'Bahamas',
  Bahrain = 'Bahrain',
  Bangladesh = 'Bangladesh',
  Barbados = 'Barbados',
  Belarus = 'Belarus',
  Belgium = 'Belgium',
  Belize = 'Belize',
  Benin = 'Benin',
  Bermuda = 'Bermuda',
  Bhutan = 'Bhutan',
  Bolivia = 'Bolivia',
  BosniaAndHerzegovina = 'Bosnia and Herzegovina',
  Botswana = 'Botswana',
  BouvetIsland = 'Bouvet Island',
  Brazil = 'Brazil',
  BritishIndianOceanTerritory = 'British Indian Ocean Territory',
  BruneiDarussalam = 'Brunei Darussalam',
  Bulgaria = 'Bulgaria',
  BurkinaFaso = 'Burkina Faso',
  Burundi = 'Burundi',
  Cambodia = 'Cambodia',
  Cameroon = 'Cameroon',
  Canada = 'Canada',
  CapeVerde = 'Cape Verde',
  CaymanIslands = 'Cayman Islands',
  CentralAfricanRepublic = 'Central African Republic',
  Chad = 'Chad',
  Chile = 'Chile',
  China = 'China',
  ChristmasIsland = 'Christmas Island',
  CocosKeelingIslands = 'Cocos Keeling Islands',
  Colombia = 'Colombia',
  Comoros = 'Comoros',
  Congo = 'Congo',
  CongoDemocraticRepublic = 'Congo Democratic Republic',
  CookIslands = 'Cook Islands',
  CostaRica = 'Costa Rica',
  CoteDIvoire = 'CoteDIvoire',
  Croatia = 'Croatia',
  Cuba = 'Cuba',
  Curacao = 'Curaçao',
  Cyprus = 'Cyprus',
  CzechRepublic = 'CzechRepublic',
  Denmark = 'Denmark',
  Djibouti = 'Djibouti',
  Dominica = 'Dominica',
  DominicanRepublic = 'Dominican Republic',
  Ecuador = 'Ecuador',
  Egypt = 'Egypt',
  ElSalvador = 'El Salvador',
  EquatorialGuinea = 'Equatorial Guinea',
  Eritrea = 'Eritrea',
  Estonia = 'Estonia',
  Ethiopia = 'Ethiopia',
  FalklandIslands = 'Falkland Islands',
  FaroeIslands = 'Faroe Islands',
  Fiji = 'Fiji',
  Finland = 'Finland',
  France = 'France',
  FrenchGuiana = 'French Guiana',
  FrenchPolynesia = 'French Polynesia',
  FrenchSouthernTerritories = 'French Southern Territories',
  Gabon = 'Gabon',
  Gambia = 'Gambia',
  Georgia = 'Georgia',
  Germany = 'Germany',
  Ghana = 'Ghana',
  Gibraltar = 'Gibraltar',
  Greece = 'Greece',
  Greenland = 'Greenland',
  Grenada = 'Grenada',
  Guadeloupe = 'Guadeloupe',
  Guam = 'Guam',
  Guatemala = 'Guatemala',
  Guernsey = 'Guernsey',
  Guinea = 'Guinea',
  GuineaBissau = 'Guinea Bissau',
  Guyana = 'Guyana',
  Haiti = 'Haiti',
  HeardIslandMcdonaldIslands = 'Heard Island Mcdonald Islands',
  HolySeeVaticanCityState = 'Holy See Vatican City State',
  Honduras = 'Honduras',
  HongKong = 'HongKong',
  Hungary = 'Hungary',
  Iceland = 'Iceland',
  India = 'India',
  Indonesia = 'Indonesia',
  Iran = 'Iran',
  Iraq = 'Iraq',
  Ireland = 'Ireland',
  IsleOfMan = 'Isle Of Man',
  Israel = 'Israel',
  Italy = 'Italy',
  Jamaica = 'Jamaica',
  Japan = 'Japan',
  Jersey = 'Jersey',
  Jordan = 'Jordan',
  Kazakhstan = 'Kazakhstan',
  Kenya = 'Kenya',
  Kiribati = 'Kiribati',
  KoreaRepublicOf = 'Korea, Republic of',
  KoreaDemocraticPeoplesRepublicOf = "Korea, Democratic People's Republic of",
  Kuwait = 'Kuwait',
  Kyrgyzstan = 'Kyrgyzstan',
  LaoPeoplesDemocraticRepublic = 'Lao Peoples Democratic Republic',
  Latvia = 'Latvia',
  Lebanon = 'Lebanon',
  Lesotho = 'Lesotho',
  Liberia = 'Liberia',
  LibyanArabJamahiriya = 'Libyan Arab Jamahiriya',
  Liechtenstein = 'Liechtenstein',
  Lithuania = 'Lithuania',
  Luxembourg = 'Luxembourg',
  Macao = 'Macao',
  Madagascar = 'Madagascar',
  Malawi = 'Malawi',
  Malaysia = 'Malaysia',
  Maldives = 'Maldives',
  Mali = 'Mali',
  Malta = 'Malta',
  MarshallIslands = 'Marshall Islands',
  Martinique = 'Martinique',
  Mauritania = 'Mauritania',
  Mauritius = 'Mauritius',
  Mayotte = 'Mayotte',
  Mexico = 'Mexico',
  Micronesia = 'Micronesia',
  Moldova = 'Moldova',
  Monaco = 'Monaco',
  Mongolia = 'Mongolia',
  Montenegro = 'Montenegro',
  Montserrat = 'Montserrat',
  Morocco = 'Morocco',
  Mozambique = 'Mozambique',
  Myanmar = 'Myanmar',
  Namibia = 'Namibia',
  Nauru = 'Nauru',
  Nepal = 'Nepal',
  Netherlands = 'Netherlands',
  NewCaledonia = 'New Caledonia',
  NewZealand = 'New Zealand',
  Nicaragua = 'Nicaragua',
  Niger = 'Niger',
  Nigeria = 'Nigeria',
  Niue = 'Niue',
  NorfolkIsland = 'Norfolk Island',
  NorthernMarianaIslands = 'Northern Mariana Islands',
  Norway = 'Norway',
  Oman = 'Oman',
  Pakistan = 'Pakistan',
  Palau = 'Palau',
  PalestinianTerritory = 'Palestinian Territory',
  Panama = 'Panama',
  PapuaNewGuinea = 'Papua New Guinea',
  Paraguay = 'Paraguay',
  Peru = 'Peru',
  Philippines = 'Philippines',
  Pitcairn = 'Pitcairn',
  Poland = 'Poland',
  Portugal = 'Portugal',
  PuertoRico = 'Puerto Rico',
  Qatar = 'Qatar',
  Reunion = 'Reunion',
  RepublicOfNorthMacedonia = 'Republic of North Macedonia',
  Romania = 'Romania',
  RussianFederation = 'Russian Federation',
  Rwanda = 'Rwanda',
  SaintBarthelemy = 'Saint Barthelemy',
  SaintHelena = 'Saint Helena',
  SaintKittsAndNevis = 'Saint Kitts And Nevis',
  SaintLucia = 'Saint Lucia',
  SaintMartin = 'Saint Martin',
  SaintPierreAndMiquelon = 'Saint Pierre And Miquelon',
  SaintVincentAndGrenadines = 'Saint Vincent And Grenadines',
  Samoa = 'Samoa',
  SanMarino = 'San Marino',
  SaoTomeAndPrincipe = 'Sao Tome And Principe',
  SaudiArabia = 'Saudi Arabia',
  Senegal = 'Senegal',
  Serbia = 'Serbia',
  Seychelles = 'Seychelles',
  SierraLeone = 'Sierra Leone',
  Singapore = 'Singapore',
  Slovakia = 'Slovakia',
  Slovenia = 'Slovenia',
  SolomonIslands = 'Solomon Islands',
  Somalia = 'Somalia',
  SouthAfrica = 'South Africa',
  SouthGeorgiaAndSandwichIsland = 'South Georgia And Sandwich Island',
  Spain = 'Spain',
  SriLanka = 'Sri Lanka',
  Sudan = 'Sudan',
  SouthSudan = 'South Sudan',
  Suriname = 'Suriname',
  SvalbardAndJanMayen = 'Svalbard And Jan Mayen',
  Swaziland = 'Swaziland',
  Sweden = 'Sweden',
  Switzerland = 'Switzerland',
  SintMaartenDutchPart = 'Sint Maarten (Dutch part)',
  SyrianArabRepublic = 'Syrian Arab Republic',
  Taiwan = 'Taiwan',
  Tajikistan = 'Tajikistan',
  Tanzania = 'Tanzania',
  Thailand = 'Thailand',
  TimorLeste = 'TimorLeste',
  Togo = 'Togo',
  Tokelau = 'Tokelau',
  Tonga = 'Tonga',
  TrinidadAndTobago = 'Trinidad And Tobago',
  Tunisia = 'Tunisia',
  Turkey = 'Turkey',
  Turkmenistan = 'Turkmenistan',
  TurksAndCaicosIslands = 'Turks And Caicos Islands',
  Tuvalu = 'Tuvalu',
  Uganda = 'Uganda',
  Ukraine = 'Ukraine',
  UnitedArabEmirates = 'United Arab Emirates',
  UnitedKingdom = 'United Kingdom',
  UnitedStates = 'United States',
  UnitedStatesOutlyingIslands = 'United States Outlying Islands',
  Uruguay = 'Uruguay',
  Uzbekistan = 'Uzbekistan',
  Vanuatu = 'Vanuatu',
  Venezuela = 'Venezuela',
  Vietnam = 'Vietnam',
  VirginIslandsBritish = 'Virgin Islands British',
  VirginIslandsUS = 'Virgin Islands US',
  WallisAndFutuna = 'Wallis And Futuna',
  WesternSahara = 'Western Sahara',
  Yemen = 'Yemen',
  Zambia = 'Zambia',
  Zimbabwe = 'Zimbabwe'
}

export interface SearchDeviceApplicationRequestDto {
  /** Название */
  term: string;
  /** Код страны */
  country_code: CountryCode;
}

export enum DeviceApplicationSortField {
  DisplayName = 'display_name',
  Version = 'version',
  Source = 'source'
}

export interface GetDeviceApplicationListRequestDto {
  /** Поиск */
  search?: string;
  /** Поле сортировки */
  sort_field?: DeviceApplicationSortField;
  /** Порядок сортировки */
  sort_order?: PaginationSortOrder;
}

export interface DeviceApplicationDto {
  /**
   * Идентификатор
   * @format uuid
   */
  id: string;
  /**
   * Создано
   * @format date-time
   */
  created_at: string;
  /**
   * Обновлено
   * @format date-time
   */
  updated_at: string;
  /** Отображаемое имя */
  display_name: string;
  /** Версия приложения */
  version: string;
  /** Источник приложения */
  source: string;
  /** Идентификатор пакета */
  bundle_id: string;
  /** URL-адрес для просмотра приложения */
  app_view_url: string;
  /** Бесплатное приложение */
  free: boolean;
  /** Флаг управления */
  management_flags?: number;
  /** Записи в области действия */
  scope_entries?: ScopeEntrySimpleDto[];
  /** Имеет записи в области действия */
  scoped: boolean;
  /** Количество устройств в области действия */
  scoped_devices_count: number;
}

export interface GetDeviceApplicationListResponseDto {
  /** Приложения устройств */
  apps: DeviceApplicationDto[];
  /** Количество */
  count: number;
}

export interface SearchDeviceApplication {
  /** Идентификатор пакета */
  bundleId: string;
  /** Цена */
  price: number;
  /** Форматированная цена */
  formattedPrice: string;
  /** Версия приложения */
  version: string;
  /** Имя продавца */
  sellerName: string;
  /** Название прилодения */
  trackName: string;
  /** URL-адрес для просмотра приложения */
  trackViewUrl: string;
  /** Логотип */
  artworkUrl60: string;
  /** Поддерживаемые устройства */
  supportedDevices: string[];
}

export interface SearchDeviceApplicationsResponseDto {
  /** @format uuid */
  id: string;
  /**
   * Создано
   * @format date-time
   */
  created_at: string;
  /**
   * Обновлено
   * @format date-time
   */
  updated_at: string;
  /** Приложения из App Store */
  ios_apps_list: SearchDeviceApplication[];
  /** Количество */
  count: number;
}

export interface DeviceApplicationScopeDto {
  /** Тип добавляемого в область действия объекта */
  type: ScopeOptionType;
  /** Тип операции включения */
  operation: ScopeEntryOperationType;
  /** Идентификатор */
  id: string;
}

export interface AddDeviceApplicationDto {
  /** Тип ОС */
  os_type: DeviceOsType;
  /**
   * Отображаемое имя
   * @maxLength 256
   */
  display_name: string;
  /** Идентификатор пакета */
  bundle_id: string;
  /** URL-адрес для просмотра приложения */
  app_view_url: string;
  /** Бесплатное приложение */
  free: boolean;
  /** Версия приложения */
  version: string;
  /** Флаг управления */
  management_flags?: number;
  /** Источник приложения */
  source?: string;
  /** Имеет записи в области действия */
  scoped?: boolean;
  /** Записи в области действия */
  scope_entries?: DeviceApplicationScopeDto[];
}

export interface UpdateDeviceApplicationDto {
  /**
   * Отображаемое имя
   * @maxLength 256
   */
  display_name?: string;
  /** Идентификатор пакета */
  bundle_id?: string;
  /** URL-адрес для просмотра приложения */
  app_view_url?: string;
  /** Версия приложения */
  version?: string;
  /** Флаг управления */
  management_flags?: number;
  /** Имеет записи в области действия */
  scoped?: boolean;
  /** Записи в области действия */
  scope_entries?: DeviceApplicationScopeDto[];
}
