import { FC, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import css from './extension-attribute.module.scss';
import useRequest from '../../../../hooks/useRequest';
import { getLocalizedErrorString } from '../../../../utils/localize-error';
import {
  extensionAttributeFormSchema,
  ExtensionAttributeFormValues
} from './components/extension-attribute-form/extension-attribute-form.schema';
import { ExtensionAttributeFormComponent } from './components/extension-attribute-form/extension-attribute-form.component';
import { SettingsPaths } from '../settings.const';
import { Paths } from '../../../constants';
import {
  CreateExtensionAttributeDto,
  ExtensionAttributeFullDto,
  ExtensionAttributeInputType,
  Permission,
  UpdateExtensionAttributeDto
} from '../../../../types/api';
import {
  createExtensionAttribute,
  deleteExtensionAttribute,
  getExtensionAttribute,
  updateExtensionAttribute
} from '../../../../api/extension-attributes';
import { Dialog, Popover, Text, TextInput, useToaster } from '@gravity-ui/uikit';
import { Header } from '../../../components/header/header.component';
import { ActionMenu } from './components/action-menu/action-menu.component';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormSkeleton } from './components/form-skeleton/form-skeleton.component';
import { usePermission } from '../../../contexts/permission.context';

export const ExtensionAttribute: FC = () => {
  const { id } = useParams();
  const isNew = id === 'new';

  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();
  const navigate = useNavigate();
  const toaster = useToaster();
  const crudRequest = useRequest<ExtensionAttributeFullDto>();
  const extensionAttributeRef = useRef<ExtensionAttributeFullDto>();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [formMode, setFormMode] = useState<'view' | 'create' | 'edit'>(isNew ? 'create' : 'view');

  const extensionAttributeForm = useForm<ExtensionAttributeFormValues>({
    mode: 'onChange',
    resolver: yupResolver(extensionAttributeFormSchema),
    defaultValues: extensionAttributeFormSchema.getDefault()
  });

  const extensionAttributeToForm = (
    input: ExtensionAttributeFullDto
  ): ExtensionAttributeFormValues => {
    const options = input.options ? input.options.map((option) => ({ value: option })) : null;

    return {
      display_name: input.display_name,
      description: input.description,
      data_type: input.data_type,
      inventory_display: input.inventory_display,
      input_type: input.input_type,
      options,
      script: input.script,
      slug: input.slug
    };
  };

  const handleExtensionAttributeCreate = async () => {
    if (crudRequest.loading) return;

    const isValid = await extensionAttributeForm.trigger();
    if (!isValid) return;
    const values = extensionAttributeForm.getValues();

    const request: CreateExtensionAttributeDto = {
      display_name: values.display_name,
      description: values.description || null,
      data_type: values.data_type,
      inventory_display: values.inventory_display,
      input_type: values.input_type,
      ...(values.slug && { slug: values.slug }),
      ...(values.options &&
        values.input_type === ExtensionAttributeInputType.Select && {
          options: values.options?.map((option) => option.value)
        }),
      ...(values.script && {
        script: values.script || undefined
      })
    };

    try {
      const response = await crudRequest.send(createExtensionAttribute(request));
      extensionAttributeRef.current = response;
      extensionAttributeForm.reset(extensionAttributeToForm(response));

      setFormMode('view');
      navigate(
        `/${Paths.SETTINGS}${SettingsPaths.EXTENSION_ATTRIBUTE.replace(':id', response.id)}`
      );
      toaster.add({
        name: 'create-success',
        content: t(
          'settings.tiles.extension_attributes.page.extension_attribute_page.successfully_created',
          {
            display_name: response.display_name
          }
        ),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      toaster.add({
        name: 'create-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  const handleExtensionAttributeEdit = () => {
    setFormMode('edit');
  };

  const handleExtensionAttributeCancel = () => {
    if (isNew) {
      navigate('./../');
      return;
    }
    setFormMode('view');
    if (extensionAttributeRef.current)
      extensionAttributeForm.reset(extensionAttributeToForm(extensionAttributeRef.current));
  };

  const handleExtensionAttributeSave = async () => {
    if (crudRequest.loading || !extensionAttributeRef.current) {
      return;
    }
    const isValid = await extensionAttributeForm.trigger();
    if (!isValid) return;

    const values = extensionAttributeForm.getValues();
    const request: UpdateExtensionAttributeDto = {
      ...(values.display_name && { display_name: values.display_name }),
      description: values.description || null,
      ...(values.data_type && { data_type: values.data_type }),
      ...(values.inventory_display && { inventory_display: values.inventory_display }),
      ...(values.input_type && { input_type: values.input_type }),
      ...(values.options &&
        values.input_type === ExtensionAttributeInputType.Select && {
          options: values.options.map((option) => option.value)
        }),
      ...(values.slug && { slug: values.slug }),
      ...(values.script && { script: values.script })
    };

    try {
      const response = await crudRequest.send(
        updateExtensionAttribute(extensionAttributeRef.current?.id, request)
      );
      extensionAttributeRef.current = response;
      extensionAttributeForm.reset(extensionAttributeToForm(response));

      setFormMode('view');
      navigate(
        `/${Paths.SETTINGS}${SettingsPaths.EXTENSION_ATTRIBUTE.replace(':id', response.id)}`
      );
      toaster.add({
        name: 'update-success',
        content: t(
          'settings.tiles.extension_attributes.page.extension_attribute_page.successfully_updated',
          {
            display_name: response.display_name
          }
        ),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      toaster.add({
        name: 'update-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  const handleExtensionAttributeDelete = async () => {
    if (crudRequest.loading || !extensionAttributeRef.current) return;
    try {
      const response = await crudRequest.send(
        deleteExtensionAttribute(extensionAttributeRef.current?.id)
      );

      navigate(`/${Paths.SETTINGS}${SettingsPaths.EXTENSION_ATTRIBUTES_LIST}`);
      toaster.add({
        name: 'update-success',
        content: t(
          'settings.tiles.extension_attributes.page.extension_attribute_page.successfully_deleted',
          { display_name: response.display_name }
        ),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (e) {
      const localizedErrorString = getLocalizedErrorString(e as Error);
      toaster.add({
        name: 'update-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  const handleDeleteModalOpen = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };

  useEffect(() => {
    const init = async () => {
      if (!id) return;
      try {
        if (!isNew) {
          const response = await crudRequest.send(getExtensionAttribute(id));
          extensionAttributeRef.current = response;
          extensionAttributeForm.reset(extensionAttributeToForm(response));
        }
      } catch (error) {
        const localizedErrorString = getLocalizedErrorString(error as Error);
        toaster.add({
          name: 'fetch-error',
          content: localizedErrorString,
          theme: 'danger',
          autoHiding: 5000
        });
      }
    };
    void init();
  }, [id]);

  return (
    <div className={css.Root}>
      <Header
        breadcrumbsTitle={extensionAttributeRef.current?.display_name}
        rightContent={
          <ActionMenu
            mode={formMode}
            disabled={crudRequest.loading || !isAllowedTo(Permission.EditDevices)}
            onExtensionAttributeCancel={handleExtensionAttributeCancel}
            onExtensionAttributeCreate={handleExtensionAttributeCreate}
            onExtensionAttributeEditStart={handleExtensionAttributeEdit}
            onExtensionAttributeEdit={handleExtensionAttributeSave}
            onExtensionAttributeDelete={handleDeleteModalOpen}
          />
        }
      />
      <div className={css.Content}>
        {crudRequest.loading ? (
          <FormSkeleton />
        ) : (
          <>
            <div className={css.Title}>
              {formMode === 'view' && (
                <Text variant="display-2">{extensionAttributeRef.current?.display_name}</Text>
              )}
              {formMode !== 'view' && (
                <Popover content={t('common.click_to_edit')}>
                  <TextInput
                    placeholder={t(
                      'settings.tiles.extension_attributes.page.extension_attribute_page.display_name'
                    )}
                    size="xl"
                    view="clear"
                    error={extensionAttributeForm.formState.errors.display_name?.message}
                    errorPlacement="inside"
                    controlProps={{ className: css.TitleInput }}
                    {...extensionAttributeForm.register('display_name')}
                  />
                </Popover>
              )}
            </div>
            <ExtensionAttributeFormComponent mode={formMode} form={extensionAttributeForm} />
          </>
        )}
      </div>
      <Dialog onClose={handleDeleteModalClose} open={isDeleteModalOpen}>
        <Dialog.Header
          caption={t(
            'settings.tiles.extension_attributes.page.extension_attribute_page.modal_delete.title'
          )}
        />
        <Dialog.Body>
          <Text>
            {t(
              'settings.tiles.extension_attributes.page.extension_attribute_page.modal_delete.message',
              {
                display_name: extensionAttributeRef.current?.display_name
              }
            )}
          </Text>
        </Dialog.Body>
        <Dialog.Footer
          textButtonApply={t(
            'settings.tiles.extension_attributes.page.extension_attribute_page.modal_delete.delete_btn'
          )}
          textButtonCancel={t('common.modal.cancel_btn')}
          onClickButtonApply={handleExtensionAttributeDelete}
          onClickButtonCancel={handleDeleteModalClose}
        />
      </Dialog>
    </div>
  );
};
