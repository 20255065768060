import { ChangeHandler, FieldNamesMarkedBoolean, FieldValues } from 'react-hook-form';
import { UseFormRegisterReturn } from 'react-hook-form/dist/types/form';

export const filterChangedFormFields = <T extends FieldValues>(
  allFields: T,
  dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<T>>>
): Partial<T> => {
  return Object.keys(dirtyFields).reduce((acc, currentField) => {
    return {
      ...acc,
      [currentField]: allFields[currentField]
    };
  }, {} as Partial<T>);
};

export function modifyRegister<T extends string>(
  register: UseFormRegisterReturn<T>,
  fn: (value: string) => string
): UseFormRegisterReturn<T> {
  const patched: ChangeHandler = async (event): Promise<void | boolean> => {
    const target: HTMLInputElement = event.target;
    target.value = fn(target.value);
    return register.onChange(event);
  };
  return {
    ...register,
    onChange: patched
  };
}
