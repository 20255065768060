import { FC, FormEvent, useEffect, useRef, useState } from 'react';
import css from './settings-ldap.module.scss';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LdapInstanceFullDto, Permission } from '../../../../types/api';
import { usePermission } from '../../../contexts/permission.context';
import { LdapForm, ldapFormSchema } from './settings-ldap.schema';
import { getCurrentLdapInstance, saveLdapInstance, testLdapConnection } from '../../../../api/ldap';
import useRequest from '../../../../hooks/useRequest';
import { syncDeviceUsersViaLDAP } from '../../../../api/device-users';
import { FileInput } from '../../../components/file-input-v2/file-input.component';
import {
  Button,
  Checkbox,
  Dialog,
  Disclosure,
  Icon,
  Label,
  Text,
  TextInput,
  useToaster
} from '@gravity-ui/uikit';
import { getLocalizedErrorString } from '../../../../utils/localize-error';
import { Header } from '../../../components/header/header.component';
import { Eye, EyeSlash } from '@gravity-ui/icons';
import { ActionMenu } from './components/action-menu/action-menu.component';
import { FormSkeleton } from './components/form-skeleton/form-skeleton.component';

interface IEyeButtonProps {
  opened: boolean;
  onClick: () => void;
}

const EyeButton = (props: IEyeButtonProps) => {
  const { opened, onClick } = props;
  return (
    <Button view="flat" size="xs" onClick={onClick}>
      <Icon data={opened ? Eye : EyeSlash} />
    </Button>
  );
};

export const SettingsLdap: FC = () => {
  const [hasInstance, setHasInstance] = useState<boolean | null>(null);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [formMode, setFormMode] = useState<'view' | 'update'>('view');
  const [isPasswordEditable, setIsPasswordEditable] = useState(true);
  const [isPasswordShow, setIsPasswordShow] = useState(false);
  const [hasCaCertificate, setHasCaCertificate] = useState(false);
  const [isCaCertificateEditable, setIsCaCertificateEditable] = useState(false);
  const [testConnectionResult, setTestConnectionResult] = useState<boolean | null>(null);
  const [isSyncModalOpen, setIsSyncModalOpen] = useState(false);
  const instanceRef = useRef<LdapInstanceFullDto>();
  const { register, formState, trigger, getValues, reset, setValue, watch, control } =
    useForm<LdapForm>({
      mode: 'onChange',
      resolver: yupResolver(ldapFormSchema),
      defaultValues: ldapFormSchema.getDefault()
    });
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();
  const toaster = useToaster();
  const initRequest = useRequest<LdapInstanceFullDto>();
  const saveRequest = useRequest<LdapInstanceFullDto>();
  const testConnectionRequest = useRequest();
  const syncRequest = useRequest();

  const resetForm = (instance?: LdapInstanceFullDto) => {
    if (instance) {
      reset({
        ...instance,
        port: instance.port || '',
        base_dn: instance.base_dn || '',
        filter: instance.filter || '',
        user_dn: instance.user_dn || '',
        password: instance.password || '',
        username_mapping_key: instance.mappings.username,
        full_name_mapping_key: instance.mappings.full_name,
        email_mapping_key: instance.mappings.email
      });
    } else {
      reset();
    }
  };

  const handleAddInstance = () => {
    setIsFormOpen(true);
  };
  const handleLdapEditStart = () => {
    setFormMode('update');
    if (instanceRef.current?.password) setIsPasswordEditable(false);
  };
  const handleLdapCancel = () => {
    if (saveRequest.loading) return;
    setTestConnectionResult(null);
    setIsFormOpen(false);
    setIsCaCertificateEditable(false);
    if (hasInstance) {
      setFormMode('view');
    }
    resetForm(instanceRef.current);
  };
  const handleFormSubmit = (event?: FormEvent) => {
    if (saveRequest.loading) return;
    event?.preventDefault();
  };
  const handleLdapSave = async () => {
    if (saveRequest.loading) return;

    const isValid = await trigger();
    if (!isValid) return;

    const values = getValues();
    let caCertificateBase64 = '';
    const caCertificateFile = values.ca_certificate;
    if (caCertificateFile) {
      const arrayBuffer = await caCertificateFile.arrayBuffer();
      caCertificateBase64 = btoa(String.fromCharCode(...new Uint8Array(arrayBuffer)));
    }

    try {
      const result = await saveRequest.send(
        saveLdapInstance({
          display_name: values.display_name,
          hostname: values.hostname,
          ssl: values.ssl,
          port: values.port || null,
          base_dn: values.base_dn || null,
          filter: values.filter || null,
          user_dn: values.user_dn || null,
          timeout_connect: Number(values.timeout_connect),
          timeout_search: Number(values.timeout_search),
          ...(values.sync_enabled !== undefined && { sync_enabled: values.sync_enabled }),
          ...(values.sync_interval !== undefined && {
            sync_interval: Number(values.sync_interval)
          }),
          mappings: {
            ...(values.username_mapping_key && { username: values.username_mapping_key }),
            ...(values.full_name_mapping_key && {
              full_name: values.full_name_mapping_key
            }),
            ...(values.email_mapping_key && { email: values.email_mapping_key })
          },
          ...(isPasswordEditable && { password: values.password || null }),
          ...(caCertificateBase64 && { ca_certificate: caCertificateBase64 })
        })
      );
      resetForm(result);
      instanceRef.current = result;
      setHasInstance(true);
      setIsCaCertificateEditable(false);
      setHasCaCertificate(result.has_ca_certificate);

      setFormMode('view');
      toaster.add({
        name: 'save-success',
        content: t('settings.tiles.ldap.page.successfully_updated'),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      toaster.add({
        name: 'save-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  const handleEditPassword = () => {
    setIsPasswordEditable(true);
    setValue('password', '');
  };
  const handlePasswordEyeClick = () => {
    setIsPasswordShow(!isPasswordShow);
  };
  const handleEditCaCertificate = () => {
    setIsCaCertificateEditable(true);
    setValue('ca_certificate', undefined);
  };

  const handleTestConnection = async () => {
    if (testConnectionRequest.loading) return;

    const isValid = await trigger([
      'hostname',
      'port',
      'base_dn',
      'user_dn',
      'ca_certificate',
      'timeout_connect',
      'timeout_search'
    ]);
    if (!isValid) return;

    setTestConnectionResult(null);
    const values = getValues();
    let caCertificateBase64 = '';
    const caCertificateFile = values.ca_certificate;
    if (caCertificateFile) {
      const arrayBuffer = await caCertificateFile.arrayBuffer();
      caCertificateBase64 = btoa(String.fromCharCode(...new Uint8Array(arrayBuffer)));
    }

    try {
      await testConnectionRequest.send(
        testLdapConnection({
          hostname: values.hostname,
          ssl: values.ssl,
          timeout_connect: Number(values.timeout_connect),
          timeout_search: Number(values.timeout_search),
          ...(values.port && { port: values.port }),
          ...(values.base_dn && { base_dn: values.base_dn }),
          ...(values.user_dn && { user_dn: values.user_dn }),
          ...(isPasswordEditable && values.password && { password: values.password }),
          ...(caCertificateBase64 && { ca_certificate: caCertificateBase64 })
        })
      );
      setTestConnectionResult(true);
    } catch (error) {
      console.error(error);
      setTestConnectionResult(false);
    }
  };

  const handleSyncModalOpen = () => {
    setIsSyncModalOpen(true);
  };
  const handleSyncModalClose = () => {
    if (syncRequest.loading) return;
    setIsSyncModalOpen(false);
  };
  const handleSyncModalSubmit = async () => {
    if (syncRequest.loading) return;
    try {
      await syncRequest.send(syncDeviceUsersViaLDAP(), 1000);
      setIsSyncModalOpen(false);
      toaster.add({
        name: 'sync-success',
        content: t('settings.tiles.ldap.page.sync_modal_toast_success'),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      toaster.add({
        name: 'sync-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  const init = async () => {
    try {
      const result = await initRequest.send(getCurrentLdapInstance(), 1000);
      instanceRef.current = result;

      resetForm(result);
      setHasInstance(true);
      setHasCaCertificate(result.has_ca_certificate);
    } catch (err) {
      setHasInstance(false);
      setFormMode('update');
    }
  };

  useEffect(() => {
    void init();
  }, []);

  const sslValue = watch('ssl');
  return (
    <div className={css.Root}>
      <Header
        rightContent={
          <ActionMenu
            mode={formMode}
            onLdapCancel={handleLdapCancel}
            onLdapEditStart={handleLdapEditStart}
            onLdapSave={handleLdapSave}
            isLoading={saveRequest.loading}
          />
        }
      />
      <div className={css.Content}>
        <div className={css.Header}>
          <Text variant="display-2">{t('settings.tiles.ldap.page.form_title')}</Text>
        </div>
        {initRequest.loading && <FormSkeleton />}
        {!hasInstance && !isFormOpen && !initRequest.loading && (
          <>
            <Text>{t('settings.tiles.ldap.page.not_configured_title')}</Text>
            <Button
              view="action"
              onClick={handleAddInstance}
              disabled={!isAllowedTo(Permission.EditAdministration)}
              style={{ width: 'fit-content' }}
            >
              {t('settings.tiles.ldap.page.not_configured_configure_btn')}
            </Button>
          </>
        )}
        {(hasInstance || isFormOpen) &&
          !initRequest.loading &&
          (saveRequest.loading ? (
            <FormSkeleton />
          ) : (
            <form className={css.Form} onSubmit={handleFormSubmit}>
              <fieldset
                className={css.Fieldset}
                disabled={!isAllowedTo(Permission.EditAdministration)}
              >
                <div>
                  <Text>{t('settings.tiles.ldap.page.form_label_display_name')}</Text>
                  <TextInput
                    {...register('display_name')}
                    error={formState.errors.display_name?.message}
                    disabled={formMode === 'view'}
                  />
                </div>
                <div>
                  <Text>{t('settings.tiles.ldap.page.form_label_hostname')}</Text>
                  <TextInput
                    {...register('hostname')}
                    error={formState.errors.hostname?.message}
                    disabled={formMode === 'view'}
                  />
                </div>
                <div>
                  <Text>{t('settings.tiles.ldap.page.form_label_port')}</Text>
                  <TextInput
                    {...register('port')}
                    type="number"
                    error={formState.errors.port?.message}
                    disabled={formMode === 'view'}
                  />
                </div>
                <Controller
                  name="ssl"
                  control={control}
                  render={({ field: { value, ...otherFields } }) => (
                    <Checkbox
                      {...otherFields}
                      checked={value}
                      content={<Text>{t('settings.tiles.ldap.page.form_label_ssl')}</Text>}
                      disabled={formMode === 'view'}
                    />
                  )}
                />
                {sslValue && (
                  <>
                    {!isCaCertificateEditable ? (
                      <div>
                        <Text>
                          {t('settings.tiles.ldap.page.form_ca_certificate_has_prefix')}:{' '}
                        </Text>
                        {hasCaCertificate ? (
                          <Label theme="success">
                            {t('settings.tiles.ldap.page.form_ca_certificate_has_true')}
                          </Label>
                        ) : (
                          <Label theme="danger">
                            {t('settings.tiles.ldap.page.form_ca_certificate_has_false')}
                          </Label>
                        )}
                        {formMode === 'update' && (
                          <a className={css.EditCaCertificateBtn} onClick={handleEditCaCertificate}>
                            {t('settings.tiles.ldap.page.form_ca_certificate_edit_btn')}
                          </a>
                        )}
                      </div>
                    ) : (
                      <div>
                        <Text>{t('settings.tiles.ldap.page.form_ca_certificate_label')}</Text>
                        <FileInput
                          error={formState.errors.ca_certificate?.message}
                          accept=".pem,.cer"
                          onUpdate={(file) => {
                            reset({ ...getValues(), ca_certificate: undefined });
                            setValue('ca_certificate', file);
                          }}
                          onCancel={() => reset({ ...getValues(), ca_certificate: undefined })}
                        />
                      </div>
                    )}
                  </>
                )}
                <div>
                  <Text>{t('settings.tiles.ldap.page.form_label_base_dn')}</Text>
                  <TextInput
                    {...register('base_dn')}
                    error={formState.errors.base_dn?.message}
                    disabled={formMode === 'view'}
                  />
                </div>
                <div>
                  <Text>{t('settings.tiles.ldap.page.form_label_filter')}</Text>
                  <TextInput
                    {...register('filter')}
                    error={formState.errors.filter?.message}
                    disabled={formMode === 'view'}
                  />
                </div>
                <div>
                  <Text>{t('settings.tiles.ldap.page.form_label_user_dn')}</Text>
                  <TextInput
                    {...register('user_dn')}
                    error={formState.errors.user_dn?.message}
                    disabled={formMode === 'view'}
                  />
                </div>
                <div>
                  <Text>{t('settings.tiles.ldap.page.form_label_password')}</Text>
                  <TextInput
                    type={isPasswordShow ? 'text' : 'password'}
                    {...register('password')}
                    error={formState.errors.password?.message}
                    disabled={formMode === 'view' || !isPasswordEditable}
                    endContent={
                      formMode !== 'view' &&
                      isPasswordEditable && (
                        <EyeButton onClick={handlePasswordEyeClick} opened={isPasswordShow} />
                      )
                    }
                  />
                  {formMode === 'update' && !isPasswordEditable && (
                    <a className={css.EditPasswordBtn} onClick={handleEditPassword}>
                      {t('settings.tiles.ldap.page.form_edit_password')}
                    </a>
                  )}
                </div>
                <div>
                  <Text>{t('settings.tiles.ldap.page.form_label_timeout_connect')}</Text>
                  <TextInput
                    {...register('timeout_connect')}
                    type="number"
                    error={formState.errors.timeout_connect?.message}
                    disabled={formMode === 'view'}
                  />
                </div>
                <div>
                  <Text>{t('settings.tiles.ldap.page.form_label_timeout_search')}</Text>
                  <TextInput
                    {...register('timeout_search')}
                    type="number"
                    error={formState.errors.timeout_search?.message}
                    disabled={formMode === 'view'}
                  />
                </div>
              </fieldset>
              {formMode === 'update' && (
                <div className={css.TestConnectionContainer}>
                  <Button onClick={handleTestConnection} loading={testConnectionRequest.loading}>
                    {t('settings.tiles.ldap.page.form_test_connection_btn')}
                  </Button>
                  {testConnectionResult !== null && (
                    <div className={css.TestConnectionResult}>
                      <Text>{t('settings.tiles.ldap.page.form_test_connection_result')}: </Text>
                      {testConnectionResult ? (
                        <Label theme="success">
                          {t('settings.tiles.ldap.page.form_test_connection_result_success')}
                        </Label>
                      ) : (
                        <Label theme="danger">
                          {t('settings.tiles.ldap.page.form_test_connection_result_failure')}
                        </Label>
                      )}
                    </div>
                  )}
                </div>
              )}
              {hasInstance && (
                <>
                  <Disclosure
                    className={css.Disclosure}
                    arrowPosition="end"
                    summary={
                      <Text variant="subheader-2">
                        {t('settings.tiles.ldap.page.mappings_title')}
                      </Text>
                    }
                  >
                    <div className={css.DisclosureContent}>
                      <div>
                        <Text>{t('settings.tiles.ldap.page.mappings_username_label')}</Text>
                        <TextInput
                          {...register('username_mapping_key')}
                          disabled={formMode === 'view'}
                        />
                      </div>
                      <div>
                        <Text>{t('settings.tiles.ldap.page.mappings_display_name_label')}</Text>
                        <TextInput
                          {...register('full_name_mapping_key')}
                          disabled={formMode === 'view'}
                        />
                      </div>
                      <div>
                        <Text>{t('settings.tiles.ldap.page.mappings_email_label')}</Text>
                        <TextInput
                          {...register('email_mapping_key')}
                          disabled={formMode === 'view'}
                        />
                      </div>
                    </div>
                  </Disclosure>
                  <Disclosure
                    className={css.Disclosure}
                    arrowPosition="end"
                    summary={
                      <Text variant="subheader-2">{t('settings.tiles.ldap.page.sync_title')}</Text>
                    }
                  >
                    <div className={css.DisclosureContent}>
                      <Controller
                        name="sync_enabled"
                        control={control}
                        render={({ field: { value, ...otherFields } }) => (
                          <Checkbox
                            {...otherFields}
                            checked={value}
                            content={
                              <Text>{t('settings.tiles.ldap.page.sync_enabled_label')}</Text>
                            }
                            disabled={formMode === 'view'}
                          />
                        )}
                      />
                      <div>
                        <Text>{t('settings.tiles.ldap.page.sync_interval_label')}</Text>
                        <TextInput
                          type="number"
                          {...register('sync_interval')}
                          disabled={formMode === 'view'}
                          error={formState.errors.sync_interval?.message}
                        />
                      </div>
                      <Button onClick={handleSyncModalOpen} style={{ width: 'fit-content' }}>
                        {t('settings.tiles.ldap.page.sync_manually_btn')}
                      </Button>
                    </div>
                  </Disclosure>
                </>
              )}
            </form>
          ))}
      </div>
      <Dialog onClose={handleSyncModalClose} open={isSyncModalOpen}>
        <Dialog.Header caption={t('settings.tiles.ldap.page.sync_modal_title')} />
        <Dialog.Body>
          <Text>{t('settings.tiles.ldap.page.sync_modal_text')}</Text>
        </Dialog.Body>
        <Dialog.Footer
          textButtonApply={t('settings.tiles.ldap.page.sync_modal_submit')}
          textButtonCancel={t('common.modal.cancel_btn')}
          onClickButtonApply={handleSyncModalSubmit}
          onClickButtonCancel={handleSyncModalClose}
        />
      </Dialog>
    </div>
  );
};
