import { FC, ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  GetPkiCertificatesResponseDto,
  Permission,
  PkiCertificateSimpleDto
} from '../../../../types/api';
import { useTranslation } from 'react-i18next';
import { getTrustedCaCertificates, importTrustedCaCertificate } from '../../../../api/pki';
import { Column, Table } from '../../../components/table/table.component';
import css from './settings-pki-trusted-ca-list.module.scss';
import useRequest from '../../../../hooks/useRequest';
import { Button, Dialog, Skeleton, useToaster } from '@gravity-ui/uikit';
import { usePermission } from '../../../contexts/permission.context';
import { FileInput } from '../../../components/file-input-v2/file-input.component';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  ImportTrustedCaForm,
  importTrustedCaFormSchema
} from './settings-pki-trusted-ca-list.schema';

interface IProps {
  onHeaderContentChange: (content: ReactNode) => void;
}

export const SettingsPkiTrustedCaList: FC<IProps> = (props) => {
  const { onHeaderContentChange } = props;
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();
  const toaster = useToaster();
  const navigate = useNavigate();
  const fetchRequest = useRequest<GetPkiCertificatesResponseDto>();
  const importRequest = useRequest<PkiCertificateSimpleDto>();
  const { setValue, formState, trigger, getValues, reset } = useForm<ImportTrustedCaForm>({
    mode: 'onChange',
    resolver: yupResolver(importTrustedCaFormSchema),
    defaultValues: importTrustedCaFormSchema.getDefault()
  });

  const [certificates, setCertificates] = useState<PkiCertificateSimpleDto[]>([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const fetchCertificates = async () => {
    const result = await fetchRequest.send(getTrustedCaCertificates());
    setCertificates(result.certificates);
  };

  const handleAddClick = () => setIsAddModalOpen(true);
  const handleAddModalClose = () => setIsAddModalOpen(false);
  const handleAddModalExited = () => reset();
  const handleAddModalApply = async () => {
    if (importRequest.loading) return;
    const isValid = await trigger();
    if (!isValid) return;
    const values = getValues();
    try {
      await importRequest.send(
        importTrustedCaCertificate({
          certificate: values.certificate
        })
      );
      setIsAddModalOpen(false);
      await fetchCertificates();
      toaster.add({
        name: 'import-trusted-ca-success',
        content: 'ca added',
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error) {
      toaster.add({
        name: 'import-trusted-ca-error',
        content: String(error),
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  const handleRowClick = (row: PkiCertificateSimpleDto) => {
    navigate(row.id);
  };

  const headerContent = (
    <Button
      key="add"
      disabled={!isAllowedTo(Permission.EditAdministration)}
      onClick={handleAddClick}
      view="action"
    >
      {t('settings.tiles.pki.page.import_ca_certificate_btn')}
    </Button>
  );

  useEffect(() => {
    onHeaderContentChange?.(headerContent);
    void fetchCertificates();
  }, []);

  const columns: Column<PkiCertificateSimpleDto>[] = [
    {
      id: 'subject',
      name: t('settings.tiles.pki.page.subject'),
      selector: (row) => row.subject
    }
  ];

  return (
    <>
      {fetchRequest.loading ? (
        <>
          <div className={css.TableHeader}>
            <Skeleton className={css.PaginationSkeleton} />
          </div>
          <Skeleton className={css.FormSkeleton} />
        </>
      ) : (
        <Table
          className={css.Table}
          columns={columns}
          data={certificates}
          leftContent={<div />}
          onRowClick={handleRowClick}
          emptyMessage={t('settings.tiles.pki.page.no_trusted_ca_certificates')}
        />
      )}
      <Dialog
        onTransitionExited={handleAddModalExited}
        onClose={handleAddModalClose}
        open={isAddModalOpen}
      >
        <Dialog.Header caption={t('settings.tiles.pki.page.import_ca_certificate_modal_caption')} />
        <Dialog.Body>
          <FileInput
            error={formState.errors.certificate?.message}
            accept=".pem,.cer"
            onUpdate={(file) => setValue('certificate', file)}
          />
        </Dialog.Body>
        <Dialog.Footer
          onClickButtonApply={handleAddModalApply}
          onClickButtonCancel={handleAddModalClose}
          loading={importRequest.loading}
          textButtonApply={t('settings.tiles.pki.page.import_ca_certificate_btn')}
          textButtonCancel={t('settings.tiles.pki.page.cancel_btn')}
        />
      </Dialog>
    </>
  );
};
