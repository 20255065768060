import { t } from 'i18next';
import { array, mixed, object, string } from 'yup';
import {
  ExtensionAttributeDataType,
  ExtensionAttributeInputType,
  ExtensionAttributeInventorySection
} from '../../../../../../types/api';
import i18next from '../../../../../../i18n/i18n';
import { notEmpty } from '../../../../../../utils/yup.validations';
import { SelectOption } from '@gravity-ui/uikit/build/esm/components/Select/types';

export interface ISelectOption {
  value: string;
}

export interface ExtensionAttributeFormValues {
  display_name: string;
  description: string | null;
  data_type: ExtensionAttributeDataType;
  inventory_display: ExtensionAttributeInventorySection;
  input_type: ExtensionAttributeInputType;
  options: ISelectOption[] | null;
  script: string | null;
  slug: string;
}

export const extensionAttributeDataTypeTitles: Record<ExtensionAttributeDataType, string> = {
  [ExtensionAttributeDataType.Date]: i18next.t(
    'settings.tiles.extension_attributes.page.extension_attribute_page.data_type_titles.date'
  ),
  [ExtensionAttributeDataType.Number]: i18next.t(
    'settings.tiles.extension_attributes.page.extension_attribute_page.data_type_titles.number'
  ),
  [ExtensionAttributeDataType.String]: i18next.t(
    'settings.tiles.extension_attributes.page.extension_attribute_page.data_type_titles.string'
  )
};

export const dataTypeOptions: SelectOption[] = Object.values(ExtensionAttributeDataType).map(
  (i) => ({
    content: extensionAttributeDataTypeTitles[i],
    value: i
  })
);

export const extensionAttributeInventoryDisplayTitles: Record<
  ExtensionAttributeInventorySection,
  string
> = {
  [ExtensionAttributeInventorySection.ExtensionAttributes]: i18next.t(
    'settings.tiles.extension_attributes.page.extension_attribute_page.inventory_display_titles.extension_attributes'
  ),
  [ExtensionAttributeInventorySection.General]: i18next.t(
    'settings.tiles.extension_attributes.page.extension_attribute_page.inventory_display_titles.general'
  ),
  [ExtensionAttributeInventorySection.OS]: i18next.t(
    'settings.tiles.extension_attributes.page.extension_attribute_page.inventory_display_titles.os'
  ),
  [ExtensionAttributeInventorySection.Security]: i18next.t(
    'settings.tiles.extension_attributes.page.extension_attribute_page.inventory_display_titles.security'
  ),
  [ExtensionAttributeInventorySection.Hardware]: i18next.t(
    'settings.tiles.extension_attributes.page.extension_attribute_page.inventory_display_titles.hardware'
  ),
  [ExtensionAttributeInventorySection.Storage]: i18next.t(
    'settings.tiles.extension_attributes.page.extension_attribute_page.inventory_display_titles.storage'
  ),
  [ExtensionAttributeInventorySection.Applications]: i18next.t(
    'settings.tiles.extension_attributes.page.extension_attribute_page.inventory_display_titles.applications'
  )
};

export const extensionAttributeInputTypeTitles: Record<ExtensionAttributeInputType, string> = {
  [ExtensionAttributeInputType.Text]: i18next.t(
    'settings.tiles.extension_attributes.page.extension_attribute_page.input_type_titles.text'
  ),
  [ExtensionAttributeInputType.Select]: i18next.t(
    'settings.tiles.extension_attributes.page.extension_attribute_page.input_type_titles.select'
  ),
  [ExtensionAttributeInputType.Script]: i18next.t(
    'settings.tiles.extension_attributes.page.extension_attribute_page.input_type_titles.script'
  )
};

export const inputTypeOptions: SelectOption[] = Object.values(ExtensionAttributeInputType).map(
  (i) => ({
    content: extensionAttributeInputTypeTitles[i],
    value: i
  })
);

export const inventorySectionOptions: SelectOption[] = Object.values(
  ExtensionAttributeInventorySection
).map((i) => ({
  content: extensionAttributeInventoryDisplayTitles[i],
  value: i
}));

export const extensionAttributeFormSchema = object({
  display_name: string()
    .required(
      t('settings.tiles.extension_attributes.page.extension_attribute_page.display_name_required')
    )
    .test(
      notEmpty(
        i18next.t(
          'settings.tiles.extension_attributes.page.extension_attribute_page.display_name_required'
        )
      )
    ),
  description: string().nullable(),
  data_type: mixed<ExtensionAttributeDataType>()
    .oneOf(Object.values(ExtensionAttributeDataType))
    .default(ExtensionAttributeDataType.String),
  inventory_display: mixed<ExtensionAttributeInventorySection>()
    .oneOf(Object.values(ExtensionAttributeInventorySection))
    .default(ExtensionAttributeInventorySection.ExtensionAttributes),
  input_type: mixed<ExtensionAttributeInputType>()
    .oneOf(Object.values(ExtensionAttributeInputType))
    .default(ExtensionAttributeInputType.Text),
  options: array()
    .of(object({ value: string() }))
    .when('input_type', {
      is: ExtensionAttributeInputType.Select,
      then: (schema) =>
        schema
          .required(
            t('settings.tiles.extension_attributes.page.extension_attribute_page.options_required')
          )
          .of(
            object({
              value: string().required(
                t(
                  'settings.tiles.extension_attributes.page.extension_attribute_page.options_required'
                )
              )
            })
          ),
      otherwise: (schema) => schema.nullable()
    }),
  script: string().when('input_type', {
    is: ExtensionAttributeInputType.Script,
    then: (schema) =>
      schema.required(
        t('settings.tiles.extension_attributes.page.extension_attribute_page.script_required')
      ),
    otherwise: (schema) => schema.nullable()
  }),
  slug: string().required(
    t('settings.tiles.extension_attributes.page.extension_attribute_page.slug_required')
  )
}).required();
