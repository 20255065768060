import { FC, ReactNode, useEffect, useState } from 'react';
import css from './settings-pki-trusted-ca.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { Permission, PkiCertificateSimpleDto } from '../../../../types/api';
import { useTranslation } from 'react-i18next';
import { deleteTrustedCaCertificate, getTrustedCaCertificate } from '../../../../api/pki';
import { formatDate } from '../../../../utils/time.utils';
import { Button, Dialog, Skeleton, Text, useToaster } from '@gravity-ui/uikit';
import useRequest from '../../../../hooks/useRequest';
import { getLocalizedErrorString } from '../../../../utils/localize-error';
import { usePermission } from '../../../contexts/permission.context';

interface IProps {
  onTitleChange?: (title: string) => unknown;
  onHeaderContentChange?: (content: ReactNode) => void;
}

export const SettingsPkiTrustedCa: FC<IProps> = (props) => {
  const { onTitleChange, onHeaderContentChange } = props;
  const { id } = useParams();
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();
  const toaster = useToaster();
  const navigate = useNavigate();
  const fetchRequest = useRequest<PkiCertificateSimpleDto>();
  const deleteRequest = useRequest<PkiCertificateSimpleDto>();
  const [certificate, setCertificate] = useState<PkiCertificateSimpleDto>();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleDeleteClick = () => setIsDeleteModalOpen(true);
  const handleDeleteModalClose = () => setIsDeleteModalOpen(false);
  const handleDeleteModalApply = async () => {
    if (deleteRequest.loading || !certificate?.id) return;
    try {
      await deleteRequest.send(deleteTrustedCaCertificate(certificate?.id));
      navigate('./..');
    } catch (error) {
      toaster.add({
        name: 'delete-trusted-ca-error',
        content: String(error),
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  const headerContent = (
    <Button
      key="delete"
      disabled={!isAllowedTo(Permission.EditAdministration)}
      onClick={handleDeleteClick}
      view="flat"
    >
      {t('settings.tiles.pki.page.delete_btn')}
    </Button>
  );

  useEffect(() => {
    onHeaderContentChange?.(headerContent);
    if (!id) return;
    const fetch = async () => {
      try {
        const result = await fetchRequest.send(getTrustedCaCertificate(id));
        setCertificate(result);
        onTitleChange?.(result.subject);
      } catch (error) {
        const localizedErrorString = getLocalizedErrorString(error as Error);
        toaster.add({
          name: 'fetch-pki-cert-error',
          content: localizedErrorString,
          theme: 'danger',
          autoHiding: 5000
        });
        navigate('./../');
      }
    };
    void fetch();
  }, []);

  return (
    <div>
      {fetchRequest.loading || !certificate ? (
        <Skeleton className={css.FormSkeleton} />
      ) : (
        <div className={css.CertificateInfo}>
          <div className={css.CertificateInfoItem}>
            <Text variant="subheader-2">{t('settings.tiles.pki.page.subject')}</Text>
            <Text>{certificate.subject}</Text>
          </div>
          <div className={css.CertificateInfoItem}>
            <Text variant="subheader-2">{t('settings.tiles.pki.page.serial_number')}</Text>
            <Text>{certificate.serial_number}</Text>
          </div>
          <div className={css.CertificateInfoItem}>
            <Text variant="subheader-2">{t('settings.tiles.pki.page.issue_date')}</Text>
            <Text>{formatDate(certificate.issue_date)}</Text>
          </div>
          <div className={css.CertificateInfoItem}>
            <Text variant="subheader-2">{t('settings.tiles.pki.page.expiration_date')}</Text>
            <Text>{formatDate(certificate.expiration_date)}</Text>
          </div>
        </div>
      )}
      <Dialog onClose={handleDeleteModalClose} open={isDeleteModalOpen}>
        <Dialog.Header caption={t('settings.tiles.pki.page.delete_trusted_ca_modal_caption')} />
        <Dialog.Body>
          <Text>{t('settings.tiles.pki.page.delete_trusted_ca_modal_text')}</Text>
        </Dialog.Body>
        <Dialog.Footer
          onClickButtonApply={handleDeleteModalApply}
          onClickButtonCancel={handleDeleteModalClose}
          loading={deleteRequest.loading}
          textButtonApply={t('settings.tiles.pki.page.delete_btn')}
          textButtonCancel={t('settings.tiles.pki.page.cancel_btn')}
        />
      </Dialog>
    </div>
  );
};
