import { mixed, number, object } from 'yup';
import i18next from '../../../../i18n/i18n';
import { MobileDevicesInventoryUpdateSettingsDto } from '../../../../types/api';

export interface SettingsDevicesConfigForm {
  mobile_devices_online_check_interval: number | 'custom';
  custom_interval?: number | null | undefined;
  mobile_devices_inventory_update_settings: MobileDevicesInventoryUpdateSettingsDto | null;
}

export const settingsDevicesConfigFormSchema = object({
  mobile_devices_online_check_interval: mixed().required(),
  custom_interval: number()
    .min(1, i18next.t('settings.tiles.devices_config.page.interval_should_be_more'))
    .max(24 * 30, i18next.t('settings.tiles.devices_config.page.interval_should_be_less')),
  mobile_devices_inventory_update_settings: mixed().required()
}).required();
