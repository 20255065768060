import { mixed, object } from 'yup';
import { fileExtension, fileNotEmpty } from '../../../../utils/yup.validations';
import { t } from 'i18next';

export interface ImportTrustedCaForm {
  certificate: File;
}

export const importTrustedCaFormSchema = object({
  certificate: mixed()
    .required(t('settings.tiles.pki.page.error_trusted_ca_required'))
    .test(fileNotEmpty(t('settings.tiles.pki.page.error_trusted_ca_required')))
    .test(fileExtension(['pem', 'cer']))
}).required();
