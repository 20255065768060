import { createRequest, createRequestRaw } from './create-request';
import {
  CreateCertificateFromCsrDto,
  DownloadCertificateBackupDto,
  GetPkiCertificatesRequestDto,
  GetPkiCertificatesResponseDto,
  ImportTrustedCaCertificateRequestDto,
  PkiCertificateFullDto,
  PkiCertificateSimpleDto
} from '../types/api';
import { AxiosResponse } from 'axios';

export async function getPkiCertificates(
  params?: GetPkiCertificatesRequestDto
): Promise<GetPkiCertificatesResponseDto> {
  return createRequest({ url: 'pki/certificates', params });
}

export async function getPkiCertificate(id: string): Promise<PkiCertificateFullDto> {
  return createRequest({ url: `pki/certificates/${id}` });
}

export async function getTrustedCaCertificates(): Promise<GetPkiCertificatesResponseDto> {
  return createRequest({ url: 'pki/certificates/ca/trusted' });
}

export async function getTrustedCaCertificate(id: string): Promise<PkiCertificateSimpleDto> {
  return createRequest({ url: `pki/certificates/ca/trusted/${id}` });
}

export async function importTrustedCaCertificate(
  data: ImportTrustedCaCertificateRequestDto
): Promise<PkiCertificateSimpleDto> {
  return createRequest({
    url: `pki/certificates/ca/trusted`,
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST'
  });
}

export async function deleteTrustedCaCertificate(id: string): Promise<PkiCertificateSimpleDto> {
  return createRequest({ url: `pki/certificates/ca/trusted/${id}`, method: 'DELETE' });
}

export async function getBuiltInCaCertificate(): Promise<PkiCertificateFullDto> {
  return createRequest({ url: 'pki/certificates/ca/built-in' });
}

export async function downloadCaCertificate(id: string): Promise<string> {
  return createRequest({ method: 'POST', url: `pki/certificates/${id}/download` });
}

export async function downloadProfileWithCaCertificate(id: string): Promise<string> {
  return createRequest({ method: 'POST', url: `pki/certificates/${id}/profile` });
}

export async function downloadCaBackup(
  id: string,
  data: DownloadCertificateBackupDto
): Promise<ArrayBuffer> {
  return createRequest({
    method: 'POST',
    url: `pki/certificates/${id}/backup`,
    data,
    responseType: 'arraybuffer'
  });
}

export async function createCertificateFromCSR(
  id: string,
  data: CreateCertificateFromCsrDto
): Promise<AxiosResponse<string>> {
  return createRequestRaw({
    method: 'POST',
    url: `pki/certificates/${id}/csr`,
    withCredentials: true,
    data
  });
}
