import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import ru_translation from './locales/ru.json';
import en_translation from './locales/en.json';
import { configure } from '@gravity-ui/uikit';
import intervalPlural from 'i18next-intervalplural-postprocessor';

const resources = {
  ru: {
    translation: ru_translation
  },
  en: {
    translation: en_translation
  }
};

i18next.use(intervalPlural).use(initReactI18next).init({
  resources,
  lng: 'ru'
});

configure({
  lang: 'ru'
});

export default i18next;
