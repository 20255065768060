import { FC } from 'react';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { useTranslation } from 'react-i18next';
import {
  Breadcrumbs as GravityUIBreadcrumbs,
  FirstDisplayedItemsCount,
  LastDisplayedItemsCount
} from '@gravity-ui/uikit';
import { DeviceSections, Paths } from '../../constants';
import { SettingsPaths } from '../../views/settings/settings.const';
import { BreadcrumbsLinkItem } from '@gravity-ui/uikit/build/esm/components/Breadcrumbs/Breadcrumbs';
import { useNavigate } from 'react-router-dom';

interface IProps {
  title?: string;
}

export const Breadcrumbs: FC<IProps> = (props) => {
  const { title } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const CustomBreadcrumb = () => {
    return <span>{title}</span>;
  };

  const uniqueRoutes = [
    {
      path: DeviceSections.MOBILE,
      breadcrumb: t('breadcrumbs.mobile')
    },
    {
      path: DeviceSections.COMPUTERS,
      breadcrumb: t('breadcrumbs.computers')
    },
    {
      path: `${DeviceSections.COMPUTERS}/${Paths.POLICY}`,
      breadcrumb: CustomBreadcrumb
    },
    {
      path: `${DeviceSections.COMPUTERS}/${Paths.POLICY_LIST}`,
      breadcrumb: t('breadcrumbs.policies')
    },
    {
      path: Paths.SETTINGS,
      breadcrumb: t('breadcrumbs.settings.main')
    },
    {
      path: `${Paths.SETTINGS}${SettingsPaths.FILEVAULT_ESCROW_CERTIFICATE}`,
      breadcrumb: t('breadcrumbs.settings.filevault_escrow_certificate')
    },
    {
      path: `${Paths.SETTINGS}${SettingsPaths.LICENSE}`,
      breadcrumb: t('breadcrumbs.settings.license')
    },
    {
      path: `${Paths.SETTINGS}${SettingsPaths.ORGANIZATION}`,
      breadcrumb: t('breadcrumbs.settings.organization')
    },
    {
      path: `${Paths.SETTINGS}${SettingsPaths.PACKAGE_LIST}`,
      breadcrumb: t('breadcrumbs.settings.packages')
    },
    {
      path: `${Paths.SETTINGS}${SettingsPaths.PACKAGE}`,
      breadcrumb: CustomBreadcrumb
    },
    {
      path: `${Paths.SETTINGS}${SettingsPaths.PACKAGE_POINT_LIST}`,
      breadcrumb: t('breadcrumbs.settings.package_points')
    },
    {
      path: `${Paths.SETTINGS}${SettingsPaths.PACKAGE_POINT}`,
      breadcrumb: CustomBreadcrumb
    },
    {
      path: `${Paths.SETTINGS}${SettingsPaths.UPLOAD_CERTIFICATE}`,
      breadcrumb: t('breadcrumbs.settings.upload_certificate')
    },
    {
      path: `${Paths.SETTINGS}${SettingsPaths.SCRIPT}`,
      breadcrumb: CustomBreadcrumb
    },
    {
      path: `${Paths.SETTINGS}${SettingsPaths.SCRIPT_LIST}`,
      breadcrumb: t('breadcrumbs.scripts')
    },
    {
      path: `${Paths.SETTINGS}${SettingsPaths.SCRIPTS_CONFIG}`,
      breadcrumb: t('breadcrumbs.settings.scripts_config')
    },
    {
      path: `${Paths.SETTINGS}${SettingsPaths.POLICIES_CONFIG}`,
      breadcrumb: t('breadcrumbs.settings.policies_config')
    },
    {
      path: `${Paths.SETTINGS}${SettingsPaths.USER}`,
      breadcrumb: CustomBreadcrumb
    },
    {
      path: `${Paths.SETTINGS}${SettingsPaths.USER_LIST}`,
      breadcrumb: t('breadcrumbs.users')
    },
    {
      path: `${Paths.SETTINGS}${SettingsPaths.ROLE}`,
      breadcrumb: CustomBreadcrumb
    },
    {
      path: `${Paths.SETTINGS}${SettingsPaths.ROLES_LIST}`,
      breadcrumb: t('breadcrumbs.settings.roles')
    },
    {
      path: `${Paths.SETTINGS}${SettingsPaths.EXTENSION_ATTRIBUTES_LIST}`,
      breadcrumb: t('breadcrumbs.settings.extension_attributes')
    },
    {
      path: `${Paths.SETTINGS}${SettingsPaths.EXTENSION_ATTRIBUTE}`,
      breadcrumb: CustomBreadcrumb
    },
    {
      path: Paths.HOME,
      breadcrumb: t('breadcrumbs.home')
    },
    {
      path: `${Paths.SETTINGS}${SettingsPaths.LDAP}`,
      breadcrumb: t('breadcrumbs.settings.ldap')
    },
    {
      path: `${Paths.SETTINGS}${SettingsPaths.SCEP}`,
      breadcrumb: t('breadcrumbs.settings.scep')
    },
    {
      path: `${Paths.SETTINGS}${SettingsPaths.SMART_GROUPS_CONFIG}`,
      breadcrumb: t('breadcrumbs.settings.smart_groups_config')
    },
    {
      path: `${Paths.SETTINGS}${SettingsPaths.DEVICES_CONFIG}`,
      breadcrumb: t('breadcrumbs.settings.devices_config')
    },
    {
      path: `${Paths.SETTINGS}${SettingsPaths.PKI}`,
      breadcrumb: t('breadcrumbs.settings.pki')
    },
    {
      path: `${Paths.SETTINGS}${SettingsPaths.PKI}/ca`,
      breadcrumb: t('breadcrumbs.settings.pki_ca')
    },
    {
      path: `${Paths.SETTINGS}${SettingsPaths.PKI_TRUSTED_CA_LIST}`,
      breadcrumb: 'trusted ca list'
    },
    {
      path: `${Paths.SETTINGS}${SettingsPaths.PKI_TRUSTED_CA}`,
      breadcrumb: CustomBreadcrumb
    },
    {
      path: `${Paths.SETTINGS}${SettingsPaths.PKI_CERTIFICATE}`,
      breadcrumb: CustomBreadcrumb
    },
    {
      path: Paths.DEVICE_USER,
      breadcrumb: CustomBreadcrumb
    },
    {
      path: Paths.DEVICE_USER_LIST,
      breadcrumb: t('breadcrumbs.device_users')
    },
    {
      path: Paths.STATIC_GROUP_DEVICE_USERS_LIST,
      breadcrumb: t('breadcrumbs.static_groups')
    },
    {
      path: Paths.SMART_GROUP_DEVICE_USERS_LIST,
      breadcrumb: t('breadcrumbs.smart_groups')
    },
    {
      path: Paths.STATIC_GROUP_DEVICE_USERS,
      breadcrumb: CustomBreadcrumb
    },
    {
      path: Paths.SMART_GROUP_DEVICE_USERS,
      breadcrumb: CustomBreadcrumb
    }
  ];
  const categoriesRoutes = [
    {
      path: `${Paths.INVENTORY}/:udid`,
      breadcrumb: CustomBreadcrumb
    },
    {
      path: Paths.INVENTORY,
      breadcrumb: t('breadcrumbs.inventory')
    },
    {
      path: Paths.CONFIGURATION_PROFILE,
      breadcrumb: CustomBreadcrumb
    },
    {
      path: Paths.CONFIGURATION_PROFILE_LIST,
      breadcrumb: t('breadcrumbs.profiles')
    },
    {
      path: `${Paths.SETTINGS}${SettingsPaths.EXTENSION_ATTRIBUTES_LIST}`,
      breadcrumb: t('breadcrumbs.settings.extension_attributes')
    },
    {
      path: Paths.SMART_GROUP_DEVICES,
      breadcrumb: CustomBreadcrumb
    },
    {
      path: Paths.SMART_GROUP_DEVICES_LIST,
      breadcrumb: t('breadcrumbs.smart_groups')
    },
    {
      path: Paths.STATIC_GROUP_DEVICES,
      breadcrumb: CustomBreadcrumb
    },
    {
      path: Paths.STATIC_GROUP_DEVICES_LIST,
      breadcrumb: t('breadcrumbs.static_groups')
    },
    {
      path: Paths.DEVICE_USER,
      breadcrumb: CustomBreadcrumb
    },
    {
      path: Paths.DEVICE_USER_LIST,
      breadcrumb: t('breadcrumbs.device_users')
    },
    {
      path: Paths.DEVICE_APPLICATION,
      breadcrumb: CustomBreadcrumb
    },
    {
      path: Paths.DEVICE_APPLICATION_LIST,
      breadcrumb: t('breadcrumbs.device_applications')
    }
  ];
  const sectionRoutes = [DeviceSections.COMPUTERS, DeviceSections.MOBILE].flatMap((category) => {
    return categoriesRoutes.map((route) => {
      return {
        path: `${category}/${route.path}`,
        breadcrumb: route.breadcrumb
      };
    });
  });
  const breadcrumbs: BreadcrumbsLinkItem[] = useBreadcrumbs([
    ...uniqueRoutes,
    ...sectionRoutes
  ]).map((breadcrumb) => {
    return {
      text: breadcrumb.breadcrumb as string,
      title: String(' '),
      action: (event) => {
        event.preventDefault();
        navigate(breadcrumb.match.pathname);
      },
      href: '.'
    };
  });

  return (
    <GravityUIBreadcrumbs
      firstDisplayedItemsCount={FirstDisplayedItemsCount.One}
      items={breadcrumbs}
      lastDisplayedItemsCount={LastDisplayedItemsCount.Two}
    />
  );
};
