import { FC, FormEvent, useEffect } from 'react';
import css from './settings-devices-config.module.scss';
import {
  GetSettingsResponseDto,
  MobileDevicesInventoryUpdateFrequency,
  MobileDevicesInventoryUpdateSettingsDto,
  Permission,
  UpdateSettingsDto
} from '../../../../types/api';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useRequest from '../../../../hooks/useRequest';
import { useTranslation } from 'react-i18next';
import { getSettings, updateSettings } from '../../../../api/settings';
import { usePermission } from '../../../contexts/permission.context';
import {
  SettingsDevicesConfigForm,
  settingsDevicesConfigFormSchema
} from './settings-devices-config.schema';
import { Header } from '../../../components/header/header.component';
import { Button, Select, SelectOption, Text, TextInput, useToaster } from '@gravity-ui/uikit';
import { getLocalizedErrorString } from '../../../../utils/localize-error';
import { FormSkeleton } from '../settings-scripts-config/components/form-skeleton/form-skeleton.component';
import { formatDate } from '../../../../utils/time.utils';

export const SettingsDevicesConfig: FC = () => {
  const initRequest = useRequest<Partial<GetSettingsResponseDto>>();
  const updateRequest = useRequest<Partial<GetSettingsResponseDto>>();
  const { register, setValue, trigger, getValues, watch, formState } =
    useForm<SettingsDevicesConfigForm>({
      mode: 'onChange',
      resolver: yupResolver(settingsDevicesConfigFormSchema),
      defaultValues: settingsDevicesConfigFormSchema.getDefault()
    });
  const watchCustomInterval = watch('mobile_devices_online_check_interval');
  const watchMobileDeviceInventoryUpdateSettings = watch(
    'mobile_devices_inventory_update_settings'
  );

  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();
  const toaster = useToaster();

  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();
  };

  const handleSave = async () => {
    if (initRequest.loading || updateRequest.loading) return;
    const isValid = await trigger();
    if (!isValid) return;
    const values = getValues();

    try {
      const requestBody: Partial<UpdateSettingsDto> = {
        mobile_devices_online_check_interval:
          values.mobile_devices_online_check_interval === 'custom' && Number(values.custom_interval)
            ? Number(values.custom_interval)
            : Number(values.mobile_devices_online_check_interval),
        mobile_devices_inventory_update_settings: values.mobile_devices_inventory_update_settings
      };
      await updateRequest.send(updateSettings(requestBody));
      toaster.add({
        name: 'device-config-save-success',
        content: t('settings.tiles.devices_config.page.settings_successfully_saved'),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error: unknown) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      toaster.add({
        name: 'device-config-save-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  const intervalOptions: SelectOption[] = [
    {
      content: '1',
      value: '1'
    },
    {
      content: '6',
      value: '6'
    },
    {
      content: '12',
      value: '12'
    },
    {
      content: '24',
      value: '24'
    },
    {
      content: t('settings.tiles.devices_config.page.custom_interval'),
      value: 'custom'
    }
  ];

  const mobileDevicesInventoryUpdateFrequencyOptions: SelectOption[] = Object.values(
    MobileDevicesInventoryUpdateFrequency
  ).map((value) => ({
    value,
    content: t(
      `settings.tiles.devices_config.page.mobile_devices_inventory_update.frequency_options.${value}`
    )
  }));

  const mobileDevicesInventoryUpdateTimeOptions: SelectOption[] = [];
  for (let hour = 0; hour <= 23; hour++) {
    const hourStr = hour.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
    const value0 = `${hourStr}:00`;
    const value30 = `${hourStr}:30`;
    mobileDevicesInventoryUpdateTimeOptions.push(
      {
        content: value0,
        value: value0
      },
      {
        content: value30,
        value: value30
      }
    );
  }

  const mobileDevicesInventoryUpdateWeekDayOptions: SelectOption[] = [];
  for (let day = 1; day <= 7; day++) {
    mobileDevicesInventoryUpdateWeekDayOptions.push({
      content: t(
        `settings.tiles.devices_config.page.mobile_devices_inventory_update.weekdays.${day}`
      ),
      value: `${day}`
    });
  }

  const mobileDevicesInventoryUpdateMonthDayOptions: SelectOption[] = [];
  for (let day = 1; day <= 31; day++) {
    mobileDevicesInventoryUpdateMonthDayOptions.push({
      content: `${day}`,
      value: `${day}`
    });
  }

  useEffect(() => {
    const init = async () => {
      const settings = await initRequest.send(
        getSettings([
          'mobile_devices_online_check_interval',
          'mobile_devices_inventory_update_settings'
        ])
      );
      if (
        settings.mobile_devices_online_check_interval &&
        [1, 6, 12, 24].includes(Number(settings.mobile_devices_online_check_interval))
      ) {
        setValue(
          'mobile_devices_online_check_interval',
          settings.mobile_devices_online_check_interval
        );
      } else {
        setValue('mobile_devices_online_check_interval', 'custom');
        setValue('custom_interval', Number(settings.mobile_devices_online_check_interval));
      }

      if (settings.mobile_devices_inventory_update_settings) {
        setValue(
          'mobile_devices_inventory_update_settings',
          settings.mobile_devices_inventory_update_settings
        );
      }
    };
    void init();
  }, []);

  const headerContent = (
    <Button
      view="action"
      onClick={handleSave}
      loading={updateRequest.loading}
      disabled={initRequest.loading || !isAllowedTo(Permission.EditAdministration)}
    >
      {t('settings.tiles.devices_config.page.update_btn')}
    </Button>
  );

  const handleUpdateInterval = (value: string[]) => {
    let interval: number | 'custom';
    if (value[0] === 'custom') interval = value[0];
    else interval = Number(value[0]);
    setValue(`mobile_devices_online_check_interval`, interval);
  };

  const handleChangeMobileDeviceInventoryUpdateSettings = (
    value: string[],
    field: 'frequency' | 'time' | 'weekDay' | 'monthDay'
  ) => {
    const newValue = watchMobileDeviceInventoryUpdateSettings;
    if (!newValue) {
      return;
    }

    if (field === 'frequency') {
      newValue.frequency = value[0] as MobileDevicesInventoryUpdateFrequency;
    } else if (field === 'time') {
      newValue.time = value[0];
      newValue.timezoneShiftMinutes = new Date().getTimezoneOffset();
    } else if (field === 'weekDay') {
      newValue.dayOfWeek = +value[0];
    } else if (field === 'monthDay') {
      newValue.dayOfMonth = +value[0];
    }

    setValue(`mobile_devices_inventory_update_settings`, newValue);
  };

  const disabledMobileDevicesComponents =
    !isAllowedTo(Permission.EditDevices) || !isAllowedTo(Permission.EditAdministration);

  const getTimeValue = () => {
    const { time, timezoneShiftMinutes } =
      watchMobileDeviceInventoryUpdateSettings as MobileDevicesInventoryUpdateSettingsDto;
    const [hours, minutes] = time.split(':');
    const date = new Date();
    date.setHours(+hours);
    date.setMinutes(+minutes);
    date.setTime(date.getTime() + (timezoneShiftMinutes - date.getTimezoneOffset()) * 60 * 1000);
    return formatDate(date.toISOString(), 'HH:mm');
  };

  return (
    <div className={css.Root}>
      <Header rightContent={headerContent} />
      <div className={css.Content}>
        <div className={css.Title}>
          <Text variant="display-2">{t('settings.tiles.devices_config.name')}</Text>
        </div>
        {initRequest.loading || updateRequest.loading ? (
          <FormSkeleton />
        ) : (
          <form className={css.Form} onSubmit={handleFormSubmit}>
            <div className={css.FormItem}>
              <Text>
                {t('settings.tiles.devices_config.page.mobile_devices_online_check_interval')}
              </Text>
              <Select
                options={intervalOptions}
                value={[String(watchCustomInterval)]}
                onUpdate={handleUpdateInterval}
                disabled={
                  !isAllowedTo(Permission.EditGroups) || !isAllowedTo(Permission.EditAdministration)
                }
              />
            </div>
            {watchCustomInterval === 'custom' && (
              <div className={css.FormItem}>
                <Text>{t('settings.tiles.devices_config.page.custom_interval')}</Text>
                <TextInput
                  type="text"
                  error={formState.errors.custom_interval?.message}
                  {...register('custom_interval')}
                />
              </div>
            )}
            <div className={css.FormItem}>
              <Text>
                {t(
                  'settings.tiles.devices_config.page.mobile_devices_inventory_update.frequency_title'
                )}
              </Text>
              <Select
                options={mobileDevicesInventoryUpdateFrequencyOptions}
                value={[String(watchMobileDeviceInventoryUpdateSettings?.frequency)]}
                onUpdate={(v) => handleChangeMobileDeviceInventoryUpdateSettings(v, 'frequency')}
                disabled={disabledMobileDevicesComponents}
              />
            </div>
            {watchMobileDeviceInventoryUpdateSettings?.frequency === 'oncePerMonth' && (
              <div className={css.FormItem}>
                <Text>
                  {t(
                    'settings.tiles.devices_config.page.mobile_devices_inventory_update.month_day_title'
                  )}
                </Text>
                <Select
                  options={mobileDevicesInventoryUpdateMonthDayOptions}
                  value={[String(watchMobileDeviceInventoryUpdateSettings?.dayOfMonth)]}
                  onUpdate={(v) => handleChangeMobileDeviceInventoryUpdateSettings(v, 'monthDay')}
                  disabled={disabledMobileDevicesComponents}
                />
              </div>
            )}
            {['oncePerWeek', 'oncePerTwoWeeks'].includes(
              String(watchMobileDeviceInventoryUpdateSettings?.frequency)
            ) && (
              <div className={css.FormItem}>
                <Text>
                  {t(
                    'settings.tiles.devices_config.page.mobile_devices_inventory_update.week_day_title'
                  )}
                </Text>
                <Select
                  options={mobileDevicesInventoryUpdateWeekDayOptions}
                  value={[String(watchMobileDeviceInventoryUpdateSettings?.dayOfWeek)]}
                  onUpdate={(v) => handleChangeMobileDeviceInventoryUpdateSettings(v, 'weekDay')}
                  disabled={disabledMobileDevicesComponents}
                />
              </div>
            )}
            {watchMobileDeviceInventoryUpdateSettings?.frequency && (
              <div className={css.FormItem}>
                <Text>
                  {t(
                    'settings.tiles.devices_config.page.mobile_devices_inventory_update.time_title'
                  )}
                </Text>
                <Select
                  options={mobileDevicesInventoryUpdateTimeOptions}
                  value={[getTimeValue()]}
                  onUpdate={(v) => handleChangeMobileDeviceInventoryUpdateSettings(v, 'time')}
                  disabled={disabledMobileDevicesComponents}
                />
              </div>
            )}
          </form>
        )}
      </div>
    </div>
  );
};
