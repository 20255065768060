import { FC, ReactNode, useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { SettingsPkiCertificateList } from '../settings-pki-certificate-list/settings-pki-certificate-list';
import { SettingsPkiCa } from '../settings-pki-ca/settings-pki-ca';
import { SettingsPkiCertificate } from '../settings-pki-certificate/settings-pki-certificate';
import { useTranslation } from 'react-i18next';
import { Tabs, TabsItemProps, Text } from '@gravity-ui/uikit';
import { Header } from '../../../components/header/header.component';
import css from './settings-pki.module.scss';
import { SettingsPkiTrustedCaList } from '../settings-pki-trusted-ca-list/settings-pki-trusted-ca-list';
import { SettingsPkiTrustedCa } from '../settings-pki-trusted-ca/settings-pki-trusted-ca';

enum PkiTabs {
  CERTIFICATES = 'certificates',
  MANAGE_CA = 'manage_ca',
  MANAGE_TRUSTED_CA = 'manage_trusted_ca'
}

export const SettingsPki: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [breadcrumbTitle, setBreadcrumbTitle] = useState('');
  const [headerContent, setHeaderContent] = useState<ReactNode>();

  const handleTitleChange = (title: string) => {
    setBreadcrumbTitle(title);
  };

  const navbarTabs: TabsItemProps[] = [
    { id: PkiTabs.CERTIFICATES, title: t('settings.tiles.pki.page.certificates') },
    { id: PkiTabs.MANAGE_CA, title: t('settings.tiles.pki.page.manage_ca') },
    { id: PkiTabs.MANAGE_TRUSTED_CA, title: t('settings.tiles.pki.page.trusted_ca') }
  ];

  const activeTab = () => {
    if (location.pathname.endsWith('/pki/ca')) {
      return PkiTabs.MANAGE_CA;
    } else if (location.pathname.includes('/pki/trusted-ca')) {
      return PkiTabs.MANAGE_TRUSTED_CA;
    }
    return PkiTabs.CERTIFICATES;
  };

  const handleSelectTab = (tab: string) => {
    setHeaderContent(undefined);
    if (tab === PkiTabs.MANAGE_CA) {
      navigate('ca');
    } else if (tab === PkiTabs.MANAGE_TRUSTED_CA) {
      navigate('trusted-ca');
    } else {
      navigate('');
    }
  };

  const handleSetHeaderContent = (content: ReactNode) => {
    setHeaderContent(content);
  };

  useEffect(() => {
    setBreadcrumbTitle('');
  }, [location]);

  return (
    <div className={css.Root}>
      <Header breadcrumbsTitle={breadcrumbTitle} rightContent={headerContent} />
      <div className={css.Content}>
        <div className={css.Title}>
          <Text variant="display-2">{t('settings.tiles.pki.page.pki_certificates')}</Text>
        </div>
        <Tabs
          className={css.NavBar}
          items={navbarTabs}
          activeTab={activeTab()}
          onSelectTab={handleSelectTab}
        />
        <Routes>
          <Route path="/" element={<SettingsPkiCertificateList />} />
          <Route path="/ca" element={<SettingsPkiCa />} />
          <Route
            path="/trusted-ca"
            element={<SettingsPkiTrustedCaList onHeaderContentChange={handleSetHeaderContent} />}
          />
          <Route
            path="/trusted-ca/:id"
            element={
              <SettingsPkiTrustedCa
                onTitleChange={handleTitleChange}
                onHeaderContentChange={handleSetHeaderContent}
              />
            }
          />
          <Route
            path="/:id"
            element={<SettingsPkiCertificate onTitleChange={handleTitleChange} />}
          />
        </Routes>
      </div>
    </div>
  );
};
