import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import css from './user-list.module.scss';
import { usePermission } from '../../contexts/permission.context';
import {
  DeleteDeviceUsersDto,
  DeviceUserSimpleDto,
  DeviceUserSortField,
  GetDeviceUserListRequestDto,
  GetDeviceUserListResponseDto,
  PaginationSortOrder,
  Permission,
  SearchDeviceUserByConditionDto
} from '../../../types/api';
import { deleteDeviceUsers, getDeviceUserList } from '../../../api/device-users';
import { PaginationRowsPerPageOptionsDeviceUsers } from '../../../const/pagination.const';
import { DeviceUsersUploadModal } from './components/device-users-upload-modal/device-users-upload-modal.component';
import { useDebounce } from 'use-debounce';
import { Column, PaginationState, SortState, Table } from '../../components/table/table.component';
import { id } from 'date-fns/locale';
import { Button, Checkbox, Dialog, Text, TextInput, useToaster } from '@gravity-ui/uikit';
import { Header } from '../../components/header/header.component';
import useRequest from '../../../hooks/useRequest';
import { TableSkeleton } from './components/table-skeleton/table-skeleton.component';
import noDataImage from '../../../assets/images/no-data.png';
import { getLocalizedErrorString } from '../../../utils/localize-error';

export const DeviceUserListComponent: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();
  const toaster = useToaster();

  const [users, setUsers] = useState<DeviceUserSimpleDto[]>([]);
  const [usersCount, setUsersCount] = useState(0);

  const [paginationState, setPaginationState] = useState<PaginationState>({
    page: 1,
    rowsPerPage: PaginationRowsPerPageOptionsDeviceUsers[0]
  });
  const [sortState, setSortState] = useState<SortState>();

  const fetchRequest = useRequest<GetDeviceUserListResponseDto>();
  const [filterText, setFilterText] = useState<string>('');
  const [debouncedFilterText] = useDebounce(filterText, 1000);
  const [condition, setCondition] = useState<SearchDeviceUserByConditionDto[]>([]);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [selectedUserIds, setSelectedUserIds] = useState<Set<string>>(new Set());
  const crudRequest = useRequest<DeviceUserSimpleDto>();

  const updateData = async (request: Partial<GetDeviceUserListRequestDto> = {}) => {
    const response = await fetchRequest.send(
      getDeviceUserList({
        conditions: request.conditions ?? condition,
        page: request.page ?? paginationState.page,
        limit: request.limit ?? paginationState.rowsPerPage,
        sort_order: request.sort_order ?? (sortState?.order as PaginationSortOrder),
        sort_field: request.sort_field ?? (sortState?.column as DeviceUserSortField)
      }),
      1000
    );
    setUsers(response.users);
    setUsersCount(response.count);
  };

  const handleRowClick = (row: DeviceUserSimpleDto) => {
    if (edit) return;
    navigate(row.id);
  };

  const handleChangePagination = (state: PaginationState) => {
    setPaginationState(state);
  };

  const handleChangeSort = (state?: SortState) => {
    setSortState(state);
  };

  const handleUploadModalCancel = () => {
    setIsUploadModalOpen(false);
  };

  const handleUploadModalSuccess = () => {
    void updateData();
    setIsUploadModalOpen(false);
  };

  const handleAddClick = () => {
    navigate('new');
  };

  const handleUserSelect = (event: ChangeEvent<HTMLInputElement>, row: DeviceUserSimpleDto) => {
    if (event.target.checked) {
      setSelectedUserIds(new Set([...selectedUserIds, row.id]));
    } else {
      setSelectedUserIds(new Set([...selectedUserIds].filter((i) => i !== row.id)));
    }
  };

  const isUserSelected = (row: DeviceUserSimpleDto): boolean => {
    return selectedUserIds.has(row.id);
  };

  const handleChangeSelectAllFlag = (checked: boolean) => {
    const userIds = users.map((i) => i.id);
    if (checked) {
      setSelectedUserIds(new Set([...selectedUserIds, ...userIds]));
    } else {
      setSelectedUserIds(new Set([...selectedUserIds].filter((i) => !userIds.includes(i))));
    }
  };

  useEffect(() => {
    setPaginationState((prevState) => {
      prevState.page = 1;
      return prevState;
    });
    let conditions: SearchDeviceUserByConditionDto[] = [];
    if (debouncedFilterText) {
      conditions = [
        {
          criteria: 'username',
          value: debouncedFilterText
        },
        {
          criteria: 'email',
          value: debouncedFilterText
        },
        {
          criteria: 'employee_name',
          value: debouncedFilterText
        },
        {
          criteria: 'position',
          value: debouncedFilterText
        },
        {
          criteria: 'phone_number',
          value: debouncedFilterText
        },
        {
          criteria: 'computers_count',
          value: debouncedFilterText
        },
        {
          criteria: 'mobile_devices_count',
          value: Number.isNaN(Number(debouncedFilterText))
            ? ''
            : Number(debouncedFilterText).toString()
        }
      ];
    }
    setCondition(conditions);
    setPaginationState((prev) => {
      prev.page = 1;
      return prev;
    });
    void updateData({
      page: 1,
      conditions
    });
    setSelectedUserIds(new Set());
  }, [debouncedFilterText]);

  const deviceUsersColumns: Column<DeviceUserSimpleDto>[] = [
    {
      name: t('device_users.user_list.column_username'),
      selector: (row: DeviceUserSimpleDto) => row.username,
      sortable: true,
      id: DeviceUserSortField.Username
    },
    {
      name: t('device_users.user_list.column_email'),
      selector: (row: DeviceUserSimpleDto) => row?.email,
      sortable: true,
      id: DeviceUserSortField.Email
    },
    {
      name: t('device_users.user_list.column_employee_name'),
      selector: (row: DeviceUserSimpleDto) => row?.employee_name,
      sortable: true,
      id: DeviceUserSortField.EmployeeName
    },
    {
      name: t('device_users.user_list.column_phone_number'),
      selector: (row: DeviceUserSimpleDto) => row?.phone_number,
      id: DeviceUserSortField.PhoneNumber
    },
    {
      name: t('device_users.user_list.column_position'),
      selector: (row: DeviceUserSimpleDto) => row?.position,
      sortable: true,
      id: DeviceUserSortField.Position
    },
    {
      name: t('device_users.user_list.column_computers_count'),
      selector: (row: DeviceUserSimpleDto) => row?.computers_count,
      sortable: true,
      id: DeviceUserSortField.ComputersCount
    },
    {
      name: t('device_users.user_list.column_mobile_devices_count'),
      selector: (row: DeviceUserSimpleDto) => row?.mobile_devices_count,
      sortable: true,
      id: DeviceUserSortField.MobileDevicesCount
    }
  ];

  const isBatchCheckboxChecked = users.every((i) => selectedUserIds.has(i.id));
  const isBatchCheckboxIndeterminate = users.some((i) => selectedUserIds.has(i.id));
  const deviceUsersColumnsEdit: Column<DeviceUserSimpleDto>[] = [
    {
      id: 'add',
      className: css.ColumnCheckbox,
      name: () => (
        <Checkbox
          className={css.Checkbox}
          checked={isBatchCheckboxChecked}
          indeterminate={!isBatchCheckboxChecked && isBatchCheckboxIndeterminate}
          onUpdate={(checked) => handleChangeSelectAllFlag(checked)}
        />
      ),
      selector: (row: DeviceUserSimpleDto) => (
        <Checkbox
          className={css.Checkbox}
          checked={isUserSelected(row)}
          onChange={(event) => handleUserSelect(event, row)}
        />
      ),
      sortable: false,
      align: 'right',
      width: 0
    },
    ...deviceUsersColumns
  ];

  const handleUsersDelete = async () => {
    if (crudRequest.loading) return;
    const request: DeleteDeviceUsersDto = { user_ids: [...selectedUserIds] };

    try {
      await crudRequest.send(deleteDeviceUsers(request), 1000);
      await updateData();
      toaster.add({
        name: 'user-delete-success',
        content: t('device_users.user_list.responses.successfully_deleted'),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (e) {
      const localizedErrorString = getLocalizedErrorString(e as Error);
      toaster.add({
        name: 'user-delete-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDeleteModalSubmit = async () => {
    await handleUsersDelete();
    setIsDeleteModalOpen(false);
    setEdit(false);
    setSelectedUserIds(new Set());
  };

  const handleEditClick = () => {
    if (edit) {
      setSelectedUserIds(new Set());
    }
    setEdit(!edit);
  };

  useEffect(() => {
    void updateData();
  }, [paginationState, sortState, id]);

  const headerButtons = (
    <div className={css.BtnGroup}>
      <Button
        view="action"
        disabled={fetchRequest.loading || !isAllowedTo(Permission.EditDeviceUsers)}
        onClick={handleAddClick}
      >
        {t('device_users.buttons.new')}
      </Button>
      <Button
        view="action"
        disabled={fetchRequest.loading || !isAllowedTo(Permission.EditDeviceUsers)}
        onClick={() => setIsUploadModalOpen(true)}
      >
        {t('device_users.buttons.import')}
      </Button>
      <Button
        view="action"
        disabled={fetchRequest.loading || !isAllowedTo(Permission.EditDeviceUsers)}
        onClick={handleEditClick}
      >
        {edit ? t('device_users.buttons.cancel') : t('device_users.buttons.edit')}
      </Button>
      {edit && (
        <Button
          view="action"
          disabled={
            fetchRequest.loading ||
            !isAllowedTo(Permission.EditDeviceUsers) ||
            (edit && selectedUserIds.size === 0)
          }
          onClick={() => setIsDeleteModalOpen(true)}
        >
          {t('device_users.buttons.delete')}
        </Button>
      )}
    </div>
  );

  const tableLeftContent = useMemo(() => {
    return (
      <div className={css.Filter}>
        <TextInput
          className={css.FilterInput}
          value={filterText}
          onUpdate={setFilterText}
          placeholder={t('device_users.user_list.filter.placeholder')}
          hasClear={true}
        />
      </div>
    );
  }, [filterText]);

  return (
    <div className={css.Root}>
      <Header rightContent={headerButtons} />
      <div className={css.Content}>
        <Text variant="display-2">{t('breadcrumbs.device_users')}</Text>
        {fetchRequest.loading ? (
          <TableSkeleton />
        ) : usersCount === 0 && debouncedFilterText === '' ? (
          <div className={css.NoDataContainer}>
            <img alt="no-data" src={noDataImage} />
            <Text variant="subheader-3">{t('device_users.user_list.no_data')}</Text>
            <Button
              view="action"
              disabled={fetchRequest.loading || !isAllowedTo(Permission.EditDeviceUsers)}
              onClick={handleAddClick}
            >
              {t('profiles.new_btn')}
            </Button>
          </div>
        ) : (
          <div className={css.DataTableContainer}>
            <Table
              onRowClick={handleRowClick}
              columns={edit ? deviceUsersColumnsEdit : deviceUsersColumns}
              data={users}
              pagination
              paginationState={paginationState}
              onChangePagination={handleChangePagination}
              paginationTotalRows={usersCount}
              paginationRowsPerPageOptions={PaginationRowsPerPageOptionsDeviceUsers}
              sortState={sortState}
              onChangeSort={handleChangeSort}
              leftContent={tableLeftContent}
            />
          </div>
        )}
      </div>
      <DeviceUsersUploadModal
        isOpen={isUploadModalOpen}
        onCancel={handleUploadModalCancel}
        onSubmit={handleUploadModalSuccess}
      />
      <Dialog onClose={handleDeleteModalClose} open={isDeleteModalOpen}>
        <Dialog.Header caption={t('device_users.user_list.modal_delete.title')} />
        <Dialog.Body>
          <Text>
            {t('device_users.user_list.modal_delete.message', { count: selectedUserIds.size })}
          </Text>
        </Dialog.Body>
        <Dialog.Footer
          textButtonApply={t('device_users.user_list.modal_delete.delete_btn')}
          textButtonCancel={t('common.modal.cancel_btn')}
          onClickButtonApply={handleDeleteModalSubmit}
          onClickButtonCancel={handleDeleteModalClose}
          loading={crudRequest.loading}
        />
      </Dialog>
    </div>
  );
};
